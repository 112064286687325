import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import ReceiptAction from 'redux/receipt/actions'
import { Typography } from 'antd'
import { createStructuredSelector } from 'reselect'
import { selectReceiptList, selectedReceiptList } from 'redux/receipt/selectors'
import ViewReceipts from './ViewReceipts'

const { Text } = Typography

const mapStateToProps = () =>
  createStructuredSelector({
    receipt: selectReceiptList,
    selectedReceipt: selectedReceiptList,
  })

const ReciptsComp = ({ selectedReceipt, intl: { formatMessage } }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({
      type: ReceiptAction.LOAD_ALL,
    })
  }, [dispatch])

  const path = window.location.pathname
  const userId = path.replace('/manage/accounts/', '')

  const receiptdata = selectedReceipt?.filter(data => data.accounts_id === userId)

  const receiptCount = receiptdata?.length || 0

  return (
    <div>
      <Helmet title={formatMessage({ id: 'title.helmet.services' })} />
      <div className="card">
        <div className="card-body">
          <h5 className="mb-4">
            <strong>{formatMessage({ id: 'text.receiptsList' })}</strong>
            &nbsp;&nbsp;
            <Text className="font-size-16 font-weight-bold" code>
              {formatMessage({ id: 'text.totalCount' }, { count: receiptCount })}
            </Text>
          </h5>
          <ViewReceipts />
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(ReciptsComp))
