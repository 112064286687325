/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  leadOwnerData: null,
  accountlistinfo: null,
  searchaccounts: null,
  loading: false,
  creating: false,
  updating: false,
  removing: false,
  displayUpdateAccountsDrawer: false,
  displayAccountsNotesDrawer: false,
  displayAccountManagerModal: false,
  displayAccountAssignedtoModal: false,
  displayFollowupDrawer: false,
}

export default function accountsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_UPDATE:
      return { ...state, selectedData: action.payload.account, displayUpdateAccountsDrawer: true }
    case actions.HIDE_UPDATE:
      return { ...state, selectedData: null, displayUpdateAccountsDrawer: false }
    case actions.SHOW_NOTES:
      return { ...state, selectedData: action.payload.account, displayAccountsNotesDrawer: true }
    case actions.HIDE_NOTES:
      return { ...state, selectedData: null, displayAccountsNotesDrawer: false }
    case actions.SHOW_ACCOUNT_MANAGER:
      return { ...state, selectedData: action.payload.account, displayAccountManagerModal: true }
    case actions.HIDE_ACCOUNT_MANAGER:
      return { ...state, selectedData: null, displayAccountManagerModal: false }
    case actions.SHOW_ASSIGNEDTO:
      return { ...state, selectedData: action.payload.account, displayAccountAssignedtoModal: true }
    case actions.HIDE_ASSIGNEDTO:
      return { ...state, selectedData: null, displayAccountAssignedtoModal: false }
    case actions.SHOW_FOLLOWUP:
      return { ...state, selectedData: action.payload.accounts, displayFollowupDrawer: true }
    case actions.HIDE_FOLLOWUP:
      return { ...state, selectedData: null, displayFollowupDrawer: false }
    default:
      return state
  }
}
