import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  loadalltasks,
  savetask,
  getuserlist,
  gettaskcategory,
  gettasktype,
  loadaccountnotes,
  savetasknotes,
  updatetask,
  updateduedate,
  updatetaskstatus,
  loadfilter,
} from 'api/tasks'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadalltasks)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        taskdata: response?.data?.taskdata,
      },
    })
  }
}

export function* ADD_TASKS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(savetask, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield call(LOAD_ALL)
  }
}

export function* UPDATE_TASK({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(updatetask, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_UPDATE,
    })
    yield call(LOAD_ALL)
  }
}

export function* UPDATE_DUEDATE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(updateduedate, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_DUE_DATE_UPDATE,
    })
    yield call(LOAD_ALL)
  }
}

export function* CHANGE_TASK_STATUS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(updatetaskstatus, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    yield call(LOAD_ALL)
  }
}

export function* GETUSERLIST() {
  const { response } = yield call(getuserlist)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        userProfile: response?.data?.userProfile,
      },
    })
  }
}

export function* GETTASKCATEGORY() {
  const { response } = yield call(gettaskcategory)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        taskcategory: response?.data?.taskcategory,
      },
    })
  }
}

export function* GETTASKTYPE() {
  const { response } = yield call(gettasktype)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        taskType: response?.data?.taskType,
      },
    })
  }
}

export function* GETNOTES({ payload }) {
  const { response } = yield call(loadaccountnotes, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        taskNotesInfo: response?.data?.taskNotesInfo,
      },
    })
  }
}

export function* SAVENOTE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(savetasknotes, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        taskNotesInfo: response?.data?.taskNotesInfo,
      },
    })
  }
}

export function* GETFILTER({ payload }) {
  const { response } = yield call(loadfilter, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        taskdata: response?.data?.taskdata,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.GETUSERLIST, GETUSERLIST),
    takeLatest(actions.GETTASKCATEGORY, GETTASKCATEGORY),
    takeLatest(actions.GETTASKTYPE, GETTASKTYPE),
    takeLatest(actions.ADD_TASKS, ADD_TASKS),
    takeLatest(actions.UPDATE_TASK, UPDATE_TASK),
    takeLatest(actions.GETNOTES, GETNOTES),
    takeLatest(actions.SAVENOTE, SAVENOTE),
    takeLatest(actions.UPDATE_DUEDATE, UPDATE_DUEDATE),
    takeLatest(actions.CHANGE_TASK_STATUS, CHANGE_TASK_STATUS),
    takeLatest(actions.GETFILTER, GETFILTER),
  ])
}
