import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  loadallsales,
  createSales,
  updateSales,
  addadvance,
  getbalance,
  loadallrenewals,
  createrenewals,
  changerenewalmonth,
  changeprospectmonth,
  changeprospecttype,
} from 'api/prospects'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadallsales)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        prospectsData: response?.data?.salesProspectsData,
      },
    })
  }
}

export function* LOAD_RENEWALS() {
  const { response } = yield call(loadallrenewals)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        renewalsData: response?.data?.renewalProspectsData,
      },
    })
  }
}

export function* GET_BALANCE({ payload }) {
  const { response } = yield call(getbalance, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        balanceResInfo: response?.data,
        displayViewBalance: true,
      },
    })
  }
}

export function* SAVE_SALES_PROSPECTS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creatingsales: true,
    },
  })
  const { response } = yield call(createSales, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creatingsales: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayAddSalesProspects: false,
        prospectsData: response?.data?.salesProspectsData,
      },
    })
  }
}

export function* SAVE_RENEWALS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creatingRenewals: true,
    },
  })
  const { response } = yield call(createrenewals, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creatingRenewals: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayAddRenewals: false,
        renewalsData: response?.data?.renewalProspectsData,
      },
    })
  }
}

export function* CHANGE_RENEWAL_MONTH({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      moving: true,
    },
  })
  const { response } = yield call(changerenewalmonth, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      moving: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayRenewalPopup: false,
        renewalsData: response?.data?.renewalProspectsData,
      },
    })
  }
}

export function* CHANGE_PROSPECT_MONTH({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      moving: true,
    },
  })
  const { response } = yield call(changeprospectmonth, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      moving: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayProspectPopup: false,
        prospectsData: response?.data?.salesProspectsData,
      },
    })
  }
}

export function* CHANGE_TYPE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      changing: true,
    },
  })
  const { response } = yield call(changeprospecttype, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      changing: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayProspectTypePopup: false,
        prospectsData: response?.data?.salesProspectsData,
      },
    })
  }
}

export function* ADD_ADVANCE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(addadvance, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayAddAdvance: false,
        prospectsData: response?.data?.salesProspectsData,
      },
    })
  }
}

export function* UPDATE_SALES_PROSPECTS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updatingsales: true,
    },
  })
  const _id = payload?.prospectId
  delete payload.prospectId
  const { response } = yield call(updateSales, _id, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updatingsales: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayEditSalesProspects: false,
        prospectsData: response?.data?.salesProspectsData,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_RENEWALS, LOAD_RENEWALS),
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.SAVE_RENEWALS, SAVE_RENEWALS),
    takeLatest(actions.SAVE_SALES_PROSPECTS, SAVE_SALES_PROSPECTS),
    takeLatest(actions.UPDATE_SALES_PROSPECTS, UPDATE_SALES_PROSPECTS),
    takeLatest(actions.ADD_ADVANCE, ADD_ADVANCE),
    takeLatest(actions.GET_BALANCE, GET_BALANCE),
    takeLatest(actions.CHANGE_RENEWAL_MONTH, CHANGE_RENEWAL_MONTH),
    takeLatest(actions.CHANGE_PROSPECT_MONTH, CHANGE_PROSPECT_MONTH),
    takeLatest(actions.CHANGE_TYPE, CHANGE_TYPE),
  ])
}
