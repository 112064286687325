import { createSelector } from 'reselect'

export const selectServices = state => state?.services

export const selectedServicesData = createSelector([selectServices], services => {
  const { searchservices, servicesData } = services
  if (searchservices && servicesData) {
    return servicesData.filter(
      r =>
        r?.business_name?.toLowerCase().includes(searchservices.toLowerCase()) ||
        r?.service_name?.toLowerCase().includes(searchservices.toLowerCase()) ||
        r?.username?.toLowerCase().includes(searchservices.toLowerCase()),
    )
  }
  return servicesData
})
