import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loadallsales() {
  return apiClient
    .get(`${BASE_URL}/getsalesprospects`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadallrenewals() {
  return apiClient
    .get(`${BASE_URL}/getrenewalprospects`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createSales(payload) {
  return apiClient
    .post(`${BASE_URL}/savesalesprospect`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createrenewals(payload) {
  return apiClient
    .post(`${BASE_URL}/saverenewals`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function addadvance(payload) {
  return apiClient
    .post(`${BASE_URL}/addingsalesadvance`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getbalance(payload) {
  return apiClient
    .post(`${BASE_URL}/getsalesbalance`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateSales(_id, payload) {
  return apiClient
    .put(`${BASE_URL}/updatesalesprospect/${_id}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function changerenewalmonth(payload) {
  return apiClient
    .post(`${BASE_URL}/changerenewalmonth`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function changeprospectmonth(payload) {
  return apiClient
    .post(`${BASE_URL}/changeprospectmonth`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function changeprospecttype(payload) {
  return apiClient
    .post(`${BASE_URL}/changeprospecttype`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
