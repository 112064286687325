import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loadtimeoff() {
  return apiClient
    .get(`${BASE_URL}/gettimeoffInfo`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loademployeeDetails() {
  return apiClient
    .get(`${BASE_URL}/getemployeeDetails`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getuserlist() {
  return apiClient
    .get(`${BASE_URL}/getEmployeeuserlist`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadsettings() {
  return apiClient
    .get(`${BASE_URL}/gettimeoffsettings`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getDaya(payload) {
  return apiClient
    .post(`${BASE_URL}/getDays`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function requestTimeOff(payload) {
  return apiClient
    .post(`${BASE_URL}/requestTimeOff`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function addTimeOff(payload) {
  return apiClient
    .post(`${BASE_URL}/addTimeOff`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function approveTimeOff(payload) {
  return apiClient
    .post(`${BASE_URL}/approveTimeOff`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateSettings(payload) {
  return apiClient
    .post(`${BASE_URL}/updateTimeOffSettings`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function onsubmitEnrolment(payload) {
  console.log('payload', payload)
  return apiClient
    .post(`${BASE_URL}/onsubmitEnrolment`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loaddepartment() {
  return apiClient
    .get(`${BASE_URL}/getdepartment`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loaddesignation() {
  return apiClient
    .get(`${BASE_URL}/getdesignation`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateEmployee(payload) {
  return apiClient
    .post(`${BASE_URL}/updateEmployee`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateEmployeeBank(payload) {
  return apiClient
    .post(`${BASE_URL}/updateEmployeeBank`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getemployeeTimeoff(payload) {
  return apiClient
    .post(`${BASE_URL}/getEmployeeTimeOff`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getemployeeBankDetails(payload) {
  return apiClient
    .post(`${BASE_URL}/getemployeeBankDetails`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadcalculation(payload) {
  return apiClient
    .post(`${BASE_URL}/loadcalculation`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updatePayrollConfig(payload) {
  return apiClient
    .post(`${BASE_URL}/updateemployeepayroll`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function checkPayroll(payload) {
  return apiClient
    .post(`${BASE_URL}/checkpayroll`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function employeePayslip(payload) {
  return apiClient
    .post(`${BASE_URL}/employeepayslip`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function generateemployeePayslip(payload) {
  return apiClient
    .post(`${BASE_URL}/generateemployeepayslip`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function generatepdfpayslip(payload) {
  return apiClient
    .post(`${BASE_URL}/payslippdf`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
