/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  payslipPdf: null,
  employeePayslipInfo: null,
  payrollInformation: null,
  calculationInfo: null,
  bankDetailInfo: null,
  employeeTimeOffPreset: null,
  employeeTimeOffData: null,
  departmentInfo: null,
  designationInfo: null,
  timeoffSettings: null,
  employeeDetails: null,
  profileInfo: null,
  timeoffData: null,
  userProfile: null,
  searchTimeOff: null,
  sickDays: null,
  personalDays: null,
  vacationDays: null,
  loading: false,
  approving: false,
  creating: false,
  updating: false,
  removing: false,
  generating: false,
  isShowPayroll: false,
  displayAddRequestDrawer: false,
  displayPayslipCreateDrawer: false,
  displayUpdateRequestDrawer: false,
  displayApproveModalDrawer: false,
  displayEmployeeEnrolmentModal: false,
}

export default function timeoffReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_CREATE:
      return { ...state, displayAddRequestDrawer: true }
    case actions.HIDE_CREATE:
      return { ...state, displayAddRequestDrawer: false }
    case actions.SHOW_UPDATE:
      return { ...state, displayUpdateRequestDrawer: true }
    case actions.HIDE_UPDATE:
      return { ...state, displayUpdateRequestDrawer: false }
    case actions.SHOW_MODAL:
      return { ...state, selectedData: action.payload.timeoffData, displayApproveModalDrawer: true }
    case actions.HIDE_MODAL:
      return { ...state, selectedData: null, displayApproveModalDrawer: false }
    case actions.SHOW_ENROLL:
      return { ...state, displayEmployeeEnrolmentModal: true }
    case actions.HIDE_ENROLL:
      return { ...state, displayEmployeeEnrolmentModal: false }

    case actions.SHOW_GENERATE_PAYSLIP:
      return { ...state, displayPayslipCreateDrawer: true }
    case actions.HIDE_GENERATE_PAYSLIP:
      return { ...state, displayPayslipCreateDrawer: false }
    default:
      return state
  }
}
