import React from 'react'
// import { connect, useDispatch } from 'react-redux'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Button, Modal } from 'antd'
import { createStructuredSelector } from 'reselect'
import { selectEnquiry, selectedEnquiryData } from 'redux/enquiry/selectors'
// import EnquiryAction from 'redux/enquiry/actions'

const mapStateToProps = () =>
  createStructuredSelector({
    enquiry: selectEnquiry,
    selectedEnquiry: selectedEnquiryData,
  })

const AccountsConvertModal = ({
  visible,
  enquiry,
  onCancel,
  loading,
  description,
  title,
  intl: { formatMessage },
}) => {
  // const dispatch = useDispatch()

  const sendEmail = () => {
    window.open(`https://enquiry.acculermedia.com/sendemail.php?enquiry_id=${enquiry?.selectedData?.id}`)
    // dispatch({
    //   type: EnquiryAction.SEND_EMAIL,
    //   payload: {
    //     enquiryId: enquiry?.selectedData?.id,
    //   },
    // })
  }

  return (
    <Modal title={title} visible={visible} onCancel={onCancel} footer={null}>
      <div className="font-size-16">{description}</div>
      <div className="text-right">
        <Button size="large" type="primary" danger onClick={sendEmail} loading={loading}>
          {formatMessage({ id: 'action.sendEmail' })}
        </Button>
      </div>
    </Modal>
  )
}

export default connect(mapStateToProps)(injectIntl(AccountsConvertModal))
