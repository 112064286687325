import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loadalldomainlist() {
  return apiClient
    .get(`${BASE_URL}/getthirdpartydomainlist`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function syncdomain() {
  return apiClient
    .get(`${BASE_URL}/syncdomain`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
