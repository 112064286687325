import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Form, Input, Button, Select, DatePicker } from 'antd'
import TimeOffAction from 'redux/timeoff/actions'
import moment from 'moment'

const { Option } = Select

const mapStateToProps = ({ dispatch, timeOff }) => ({
  dispatch,
  timeOff,
})

const StoreSettings = ({
  apiRes,
  deptRes,
  desigRes,
  timeOff,
  intl: { formatMessage },
  dispatch,
}) => {

  const path = window.location.pathname

  const userId = path.replace('/manage/employee/', '')

  const [form] = Form.useForm()

  useEffect(() => {
    if (apiRes) {
      form.setFieldsValue({
        name: apiRes?.name,
        emailid: apiRes?.emailid,
        mobile: apiRes?.mobile,
        department: apiRes?.department,
        designation: apiRes?.designation,
        dob: moment(apiRes?.dob),

      })
    } // eslint-disable-next-line
  }, [form, apiRes])

  const toInputLowercase = e => {
    e.target.value = e.target.value.toLowerCase()
  }

  const onFinish = values => {
    dispatch({
      type: TimeOffAction.UPDATE_EMPLOYEE,
      payload: {
        employeeId: userId,
        updatedData: values,
      },
    })
  }

  function disabledDate(current) {
    return current > moment().startOf('day')
  }

  const dateFormat = 'YYYY-MM-DD'
  const [valueone] = useState(new Date())
  const datevalue = moment(valueone).format('YYYY-MM-DD')


  return (
    <div>
      <div className="card">
        <div className="card-body">
          <Form
            form={form}
            className="mb-4"
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              name: apiRes?.name,
              emailid: apiRes?.emailid,
              mobile: apiRes?.mobile,
              department: apiRes?.department,
              designation: apiRes?.designation,
              dob: moment(apiRes?.dob),
            }}
            hideRequiredMark
          >
            <div className="row">
              <div className="col-md-6">
                <Form.Item
                  name="name"
                  label={formatMessage({ id: 'form.label.name' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.name' })}
                    maxLength={64}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item name="emailid" label={formatMessage({ id: 'form.label.emailid' })}>
                  <Input
                    type="email"
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.emailid' })}
                    maxLength={64}
                    onInput={toInputLowercase}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="mobile"
                  label={formatMessage({ id: 'form.label.mobile' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    placeholder={formatMessage({ id: 'form.placeholder.mobile' })}
                    size="large"
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item name="department" label={formatMessage({ id: 'form.label.department' })}>
                  <Select
                    placeholder={formatMessage({ id: 'form.placeholder.department' })}
                    size="large"
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {deptRes?.map(o => {
                      return (
                        <Option key={o.name} value={o.name}>
                          {o.name}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="designation"
                  label={formatMessage({ id: 'form.label.designation' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Select
                    placeholder={formatMessage({ id: 'form.placeholder.designation' })}
                    size="large"
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {desigRes?.map(o => {
                      return (
                        <Option key={o.name} value={o.name}>
                          {o.name}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item name="dob" label={formatMessage({ id: 'form.label.dob' })}>
                  <DatePicker
                    style={{ width: '100%' }}
                    size="large"
                    allowClear={false}
                    disabledDate={disabledDate}
                    selected={datevalue}
                    format={dateFormat}
                  />
                </Form.Item>
              </div>

              <div className="col-md-12">
                <Form.Item className="text-right">
                  <Button type="primary" htmlType="submit" size="large" loading={timeOff?.updating}>
                    <strong>{formatMessage({ id: 'action.update' })}</strong>
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(StoreSettings))
