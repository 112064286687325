import React, { lazy, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import Layout from 'layouts'
import IndividualAccounts from 'pages/manage/IndividualAccounts'
import IndividualEmployee from 'pages/manage/Timeoff/IndividualEmployee'

const routes = [
  {
    path: '/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/profile',
    Component: lazy(() => import('pages/MyProfile')),
    exact: true,
  },
  {
    path: '/manage/leads',
    Component: lazy(() => import('pages/manage/Leads')),
    exact: true,
  },
  {
    path: '/manage/accounts',
    Component: lazy(() => import('pages/manage/Accounts')),
    exact: true,
  },
  {
    path: '/manage/user',
    Component: lazy(() => import('pages/manage/Users')),
    exact: true,
  },
  {
    path: '/manage/invoice',
    Component: lazy(() => import('pages/manage/Invoices')),
    exact: true,
  },
  {
    path: '/manage/payments',
    Component: lazy(() => import('pages/manage/Payments')),
    exact: true,
  },
  {
    path: '/manage/enquiry',
    Component: lazy(() => import('pages/manage/Enquiry')),
    exact: true,
  },
  {
    path: '/manage/daily-reports',
    Component: lazy(() => import('pages/manage/Daily-Reports')),
    exact: true,
  },
  {
    path: '/manage/services',
    Component: lazy(() => import('pages/manage/Services')),
    exact: true,
  },
  {
    path: '/manage/renewals',
    Component: lazy(() => import('pages/manage/Renewals')),
    exact: true,
  },
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/dashboard')),
    exact: true,
  },
  {
    path: '/manage/tasks',
    Component: lazy(() => import('pages/manage/Tasks')),
    exact: true,
  },
  {
    path: '/third-party-app',
    Component: lazy(() => import('pages/ThirdPartyApp/Godaddy')),
    exact: true,
  },
  {
    path: '/manage/prospects',
    Component: lazy(() => import('pages/Prospects')),
    exact: true,
  },
  {
    path: '/manage/bulk-sms',
    Component: lazy(() => import('pages/manage/BulkSms')),
    exact: true,
  },
  {
    path: '/manage/timeoff',
    Component: lazy(() => import('pages/manage/Timeoff')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/manage/user" />} />
                    <Route exact path="/manage/accounts/:id" component={IndividualAccounts} />
                    <Route exact path="/manage/employee/:id" component={IndividualEmployee} />

                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
