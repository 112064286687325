import React, { useState, Suspense, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { baseUrlInfo } from 'services/axios'
import { injectIntl } from 'react-intl'
import { Table, Spin, Drawer } from 'antd'
import { getFormattedDate } from 'utils/parser'
import TimeOffAction from 'redux/timeoff/actions'
import GeneratePayslip from './GeneratePayslip'

const mapStateToProps = ({ dispatch, timeOff, user, settings }) => ({
  dispatch,
  timeOff,
  user,
  settings,
})

const ViewDailyReports = ({ timeOff, settings, intl: { formatMessage } }) => {
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()

  const path = window.location.pathname
  const userId = path.replace('/manage/employee/', '')

  useEffect(() => {
    dispatch({
      type: TimeOffAction.LOAD_SINGLE_PAYSLIP,
      payload: {
        employeeId: userId,
      },
    })
  }, [dispatch, userId])

  const showCreate = () => {
    dispatch({
      type: TimeOffAction.SHOW_GENERATE_PAYSLIP,
    })
  }

  const hideCreate = () => {
    dispatch({
      type: TimeOffAction.HIDE_GENERATE_PAYSLIP,
    })
  }

  const apiDataInfo = timeOff?.employeePayslipInfo

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.paySlipData' }),
  }

  const showPayslip = value => {
    dispatch({
      type: TimeOffAction.GENERATE_PAYSLIP_PDF,
      payload: {
        payslipId: value,
      },
    })
  }

  useEffect(() => {
    if (timeOff?.payslipPdf !== null) {
      window.open(`${baseUrlInfo}/${timeOff?.payslipPdf}`)
      dispatch({
        type: TimeOffAction.SET_STATE,
        payload: {
          payslipPdf: null,
        },
      })
    }
  })

  const columns = [
    {
      title: formatMessage({ id: 'text.sno' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.payslipMonth' }),
      render: (text, record) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
        <div role="button" className="font-weight-bold" onClick={() => showPayslip(record?.id)}>
          <span style={{ color: 'red !important' }}>{`${record?.month_year}`}</span>
        </div>
      ),
    },
    // {
    //   title: formatMessage({ id: 'text.month_year' }),
    //   dataIndex: 'month_year',
    //   key: 'month_year',
    // },
    {
      title: formatMessage({ id: 'text.total_working_days' }),
      dataIndex: 'total_working_days',
      key: 'total_working_days',
    },
    {
      title: formatMessage({ id: 'text.leave_days' }),
      dataIndex: 'leave_days',
      key: 'leave_days',
    },
    {
      title: formatMessage({ id: 'text.cl_adjusted_days' }),
      dataIndex: 'cl_adjusted_days',
      key: 'cl_adjusted_days',
    },
    {
      title: formatMessage({ id: 'text.lop_days' }),
      dataIndex: 'lop_days',
      key: 'lop_days',
    },
    {
      title: formatMessage({ id: 'text.created_at' }),
      render: record => <>{getFormattedDate(record?.created_at)}</>,
    },
  ]

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="mb-4">
            <div className="mb-4">
              <div className="row">
                <div className="col-md-12 text-right">
                  <button
                    type="button"
                    className="btn theme-btn-style"
                    onClick={showCreate}
                    style={{ float: 'right', marginTop: '2.5px' }}
                  >
                    <i className="fe fe-plus mr-1" aria-hidden="true" />
                    {formatMessage({ id: 'action.GeneratePaySlip' })}
                  </button>
                </div>
              </div>
            </div>
            <div className="kit__utils__table">
              <Suspense fallback={<Spin />}>
                {apiDataInfo?.length >= 0 ? (
                  <Table
                    dataSource={apiDataInfo}
                    columns={columns}
                    locale={locale}
                    rowKey="_id"
                    pagination={{
                      onChange(current) {
                        setPage(current)
                      },
                    }}
                  />
                ) : (
                  <Table
                    columns={columns}
                    locale={locale}
                    loading={{
                      indicator: (
                        <div>
                          <Spin />
                        </div>
                      ),
                    }}
                  />
                )}
              </Suspense>
            </div>
            <Drawer
              title={formatMessage({ id: 'text.generatePayslip' })}
              placement="right"
              destroyOnClose
              visible={timeOff?.displayPayslipCreateDrawer}
              onClose={hideCreate}
              width={settings?.isMobileView ? '100%' : '44%'}
            >
              <GeneratePayslip loading={timeOff?.generating} userId={userId} />
            </Drawer>
          </div>
        </div>
      </div>
    </>
  )
}

export default connect(mapStateToProps)(injectIntl(ViewDailyReports))
