import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function getemployeeInfo(payload) {
  return apiClient
    .post(`${BASE_URL}/getsingleemployee/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function saveaccountsnotes(payload) {
  return apiClient
    .post(`${BASE_URL}/saveaccountnoteinfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
