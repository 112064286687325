const actions = {
  SET_STATE: 'timeoff/SET_STATE',
  LOAD_ALL: 'timeoff/LOAD_ALL',
  LOAD_SETTINGS: 'timeoff/LOAD_SETTINGS',
  UPDATE_TIMEOFF_SETTINGS: 'timeoff/UPDATE_TIMEOFF_SETTINGS',
  SHOW_CREATE: 'timeoff/SHOW_CREATE',
  HIDE_CREATE: 'timeoff/HIDE_CREATE',
  SHOW_UPDATE: 'timeoff/SHOW_UPDATE',
  HIDE_UPDATE: 'timeoff/HIDE_UPDATE',
  GET_REMAINING_DAYS: 'timeoff/GET_REMAINING_DAYS',
  REQUEST_TIMEOFF: 'timeoff/REQUEST_TIMEOFF',
  ADD_TIMEOFF_LOGS: 'timeoff/ADD_TIMEOFF_LOGS',
  SHOW_MODAL: 'timeoff/SHOW_MODAL',
  HIDE_MODAL: 'timeoff/HIDE_MODAL',
  APPROVE_REQUEST: 'timeoff/APPROVE_REQUEST',
  LOAD_EMPLOYEE_DETAILS: 'timeoff/LOAD_EMPLOYEE_DETAILS',
  SHOW_ENROLL: 'timeoff/SHOW_ENROLL',
  HIDE_ENROLL: 'timeoff/HIDE_ENROLL',
  GETUSERLIST: 'timeoff/GETUSERLIST',
  ONSUBMIT_ENROLMENT: 'timeoff/ONSUBMIT_ENROLMENT',

  LOAD_SINGLE_EMPLOYEE: 'timeoff/LOAD_SINGLE_EMPLOYEE',
  GETDEPARTMENT: 'timeoff/GETDEPARTMENT',
  GETDESIGNATION: 'timeoff/GETDESIGNATION',

  UPDATE_EMPLOYEE: 'timeoff/UPDATE_EMPLOYEE',
  LOAD_SINGLE_TIMEOFF: 'timeoff/LOAD_SINGLE_TIMEOFF',
  LOAD_SINGLE_BANK_DETAIL: 'timeoff/LOAD_SINGLE_BANK_DETAIL',
  UPDATE_BANK_DETAILS: 'timeoff/UPDATE_BANK_DETAILS',
  CHECK_CALCULATION: 'timeoff/CHECK_CALCULATION',
  CHECK_PAYROLL: 'timeoff/CHECK_PAYROLL',
  UPDATE_PAYROLL_CONFIG: 'timeoff/UPDATE_PAYROLL_CONFIG',
  LOAD_SINGLE_PAYSLIP: 'timeoff/LOAD_SINGLE_PAYSLIP',
  SHOW_GENERATE_PAYSLIP: 'timeoff/SHOW_GENERATE_PAYSLIP',
  HIDE_GENERATE_PAYSLIP: 'timeoff/HIDE_GENERATE_PAYSLIP',
  GENERATE_PAYSLIP: 'timeoff/GENERATE_PAYSLIP',
  GENERATE_PAYSLIP_PDF: 'timeoff/GENERATE_PAYSLIP_PDF',
}

export default actions
