import PhoneNumber from 'awesome-phonenumber'
import moment from 'moment'

export const parseType = (type, types) => {
  return types[type]
}

export const phoneNumberSignificant = data => {
  if (data) {
    const phoneNumber = new PhoneNumber(data, 'CA') // todo: auto detect
    return phoneNumber.getNumber('significant')
  }
  return null
}

export const phoneNumberNational = data => {
  if (data) {
    const phoneNumber = new PhoneNumber(data, 'CA') // todo: auto detect
    return phoneNumber.getNumber('national')
  }
  return null
}

export const phoneNumberE164 = data => {
  if (data) {
    const phoneNumber = new PhoneNumber(data, 'CA') // todo: auto detect
    return phoneNumber.getNumber('e164')
  }
  return null
}

export const getFormattedMonthYear = dateTime => {
  return moment(dateTime).format('MMM, YYYY')
}

export const getFormattedDate = dateTime => {
  return moment(dateTime).format('MMM D, YYYY')
}

export const getFormattedDateWithTime = dateTime => {
  return moment(dateTime).format('MMM D, YYYY, hh:mm a')
}

export const getFormattedDateTime = (dateTime, shouldFormat) => {
  if (shouldFormat) {
    return moment(dateTime).format('MMM D, YYYY hh:mm a')
  }
  return moment(dateTime).fromNow()
}

export const secondsToHms = d => {
  d = Number(d)
  const h = Math.floor(d / 3600)
  const m = Math.floor((d % 3600) / 60)
  const s = Math.floor((d % 3600) % 60)

  const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : ''
  const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : ''
  const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : ''
  return hDisplay + mDisplay + sDisplay
}

export const secondsToHms2 = d => {
  d = Number(d)
  const s = Math.floor(d / 60) / 60
  const sDisplay = s > 0 ? s + (s === 1) : ''
  const Data = Number(sDisplay.toFixed(2))
  return Data
}

export const secondsToHms3 = d => {
  d = Number(d)
  const h = Math.floor(d / 3600)
  const m = Math.floor((d % 3600) / 60)
  const s = Math.floor((d % 3600) % 60)

  const hDisplay = h > 0 ? h + (h === 1 ? ' hr, ' : ' hrs, ') : ''
  const mDisplay = m > 0 ? m + (m === 1 ? ' min, ' : ' mins, ') : ''
  const sDisplay = s > 0 ? s + (s === 1 ? ' sec' : ' secs') : ''
  return hDisplay + mDisplay + sDisplay
}
