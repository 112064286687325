const actions = {
  SET_STATE: 'renewals/SET_STATE',

  SHOW_CREATE: 'renewals/SHOW_CREATE',
  HIDE_CREATE: 'renewals/HIDE_CREATE',
  SHOW_UPDATE: 'renewals/SHOW_UPDATE',
  HIDE_UPDATE: 'renewals/HIDE_UPDATE',

  SHOW_CREATE2: 'renewals/SHOW_CREATE2',
  HIDE_CREATE2: 'renewals/HIDE_CREATE2',
  SHOW_UPDATE2: 'renewals/SHOW_UPDATE2',
  HIDE_UPDATE2: 'renewals/HIDE_UPDATE2',

  SHOW_CREATE3: 'renewals/SHOW_CREATE3',
  HIDE_CREATE3: 'renewals/HIDE_CREATE3',
  SHOW_UPDATE3: 'renewals/SHOW_UPDATE3',
  HIDE_UPDATE3: 'renewals/HIDE_UPDATE3',

  SAVE_DOMAIN_INFO: 'renewals/SAVE_DOMAIN_INFO',
  SAVE_HOSTING_INFO: 'renewals/SAVE_HOSTING_INFO',
  UPDATE_HOSTING_INFO: 'renewals/UPDATE_HOSTING_INFO',
  UPDATE_DOMAIN_INFO: 'renewals/UPDATE_DOMAIN_INFO',
  UPDATE_EMAIL_INFO: 'renewals/UPDATE_EMAIL_INFO',

  SAVE_EMAIL_INFO: 'renewals/SAVE_EMAIL_INFO',
  LOAD_DOMAIN: 'renewals/LOAD_DOMAIN',
  LOAD_ALL: 'renewals/LOAD_ALL',
  LOAD_ALL_HOSTING: 'renewals/LOAD_ALL_HOSTING',
  LOAD_EMAIL_LIST: 'renewals/LOAD_EMAIL_LIST',

  SHOW_DELETE_DOMAIN: 'renewals/SHOW_DELETE_DOMAIN',
  HIDE_DELETE_DOMAIN: 'renewals/HIDE_DELETE_DOMAIN',
  REMOVE_DOMAIN: 'renewals/REMOVE_DOMAIN',

  SHOW_DELETE_HOSTING: 'renewals/SHOW_DELETE_HOSTING',
  HIDE_DELETE_HOSTING: 'renewals/HIDE_DELETE_HOSTING',
  REMOVE_HOSTING: 'renewals/REMOVE_HOSTING',

  SHOW_DELETE_EMAIL: 'renewals/SHOW_DELETE_EMAIL',
  HIDE_DELETE_EMAIL: 'renewals/HIDE_DELETE_EMAIL',
  REMOVE_EMAIL: 'renewals/REMOVE_EMAIL',
}

export default actions
