import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { createStructuredSelector } from 'reselect'
import {
  selectDomainNameList,
  selectedDomainNameList,
  selectedHostingList,
  SelectedEmailList,
} from 'redux/renewals/selectors'
import { selectSettings } from 'redux/settings/selectors'
import RenewalsAction from 'redux/renewals/actions'
import { Link } from 'react-router-dom'
import { Tabs } from 'antd'
import DomainInfo from './Domain'
import HostingInfo from './Hosting'
import EmailInfo from './Email'

const { TabPane } = Tabs

const mapStateToProps = () =>
  createStructuredSelector({
    domain: selectDomainNameList,
    selectedDomain: selectedDomainNameList,
    selectedHosting: selectedHostingList,
    selectedEmail: SelectedEmailList,
    settings: selectSettings,
  })

const Stores = ({
  selectedDomain,
  selectedHosting,
  selectedEmail,
  dispatch,
  intl: { formatMessage },
}) => {
  const query = new URLSearchParams(window.location.search)
  const tabname = query.get('tab')

  useEffect(() => {
    dispatch({
      type: RenewalsAction.LOAD_ALL_HOSTING,
    })
  }, [dispatch])

  useEffect(() => {
    dispatch({
      type: RenewalsAction.LOAD_ALL,
    })
  }, [dispatch])

  let storesUrlVal

  if (tabname === 'domain') {
    storesUrlVal = '1'
  } else if (tabname === 'hosting') {
    storesUrlVal = '2'
  } else if (tabname === 'email') {
    storesUrlVal = '3'
  }

  const path = window.location.pathname
  const userId = path.replace('/manage/accounts/', '')

  const DomainrenewalsData = selectedDomain?.filter(data => data.accounts_id === userId)

  const domainactivecount = DomainrenewalsData?.length || 0

  const hostingRenewalData = selectedHosting?.filter(data => data.accounts_id === userId)

  const hostingactivecount = hostingRenewalData?.length || 0

  const emailrenewalData = selectedEmail?.filter(data => data.accounts_id === userId)

  const emailactivecount = emailrenewalData?.length || 0

  useEffect(() => {
    return () => {
      dispatch({
        type: RenewalsAction.SET_STATE,
        payload: {
          searchDomainname: null,
        },
      })
    } // eslint-disable-next-line
  }, [dispatch])

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <Tabs defaultActiveKey={storesUrlVal}>
            <TabPane
              tab={
                <Link to="?tab=domain">
                  <span>
                    {formatMessage({ id: 'text.store.tab.domain' })}
                    {' ('}
                    {formatMessage({ id: 'text.space' }, { count: domainactivecount })}
                    {')'}{' '}
                  </span>
                </Link>
              }
              key={1}
            >
              <DomainInfo />
            </TabPane>
            <TabPane
              tab={
                <Link to="?tab=hosting">
                  <span>
                    {formatMessage({ id: 'text.store.tab.hosting' })}
                    {' ('}
                    {formatMessage({ id: 'text.space' }, { count: hostingactivecount })}
                    {')'}{' '}
                  </span>
                </Link>
              }
              key={2}
            >
              <HostingInfo />
            </TabPane>
            <TabPane
              tab={
                <Link to="?tab=email">
                  <span>
                    {formatMessage({ id: 'text.store.tab.email' })}
                    {' ('}
                    {formatMessage({ id: 'text.space' }, { count: emailactivecount })}
                    {')'}{' '}
                  </span>
                </Link>
              }
              key={3}
            >
              <EmailInfo />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(Stores))
