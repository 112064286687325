import React, { useState, Suspense } from 'react'
import { connect, useDispatch } from 'react-redux'
import { injectIntl } from 'react-intl'
// import moment from 'moment'
import { Input, Table, Form, Spin, Tooltip, Button } from 'antd'
import { createStructuredSelector } from 'reselect'
import { selectDailyReport, selectedDailyReportData } from 'redux/dailyreports/selectors'
import { selectSettings } from 'redux/settings/selectors'
import { selectUser } from 'redux/user/selectors'
import { getFormattedDate, getFormattedDateWithTime, secondsToHms } from 'utils/parser'
import DailyReportAction from 'redux/dailyreports/actions'
// import AddDailyReport from '../AddDailyReport'

const { Search } = Input

const mapStateToProps = () =>
  createStructuredSelector({
    dailyreports: selectDailyReport,
    selectedDailyReport: selectedDailyReportData,
    settings: selectSettings,
    user: selectUser,
  })

const ViewDailyReports = ({
  // dailyreports,
  selectedDailyReport,
  // settings,
  intl: { formatMessage },
}) => {
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()

  const onSearch = value => {
    dispatch({
      type: DailyReportAction.SET_STATE,
      payload: { searchdailyreport: value },
    })
  }

  // const showCreate = () => {
  //   dispatch({
  //     type: DailyReportAction.SHOW_CREATE,
  //   })
  // }

  // const hideCreate = () => {
  //   dispatch({
  //     type: DailyReportAction.HIDE_CREATE,
  //   })
  // }

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.dailyReportData' }),
  }

  const columns = [
    {
      title: formatMessage({ id: 'text.viewDailyReport.sno' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.viewDailyReport.business_name' }),
      dataIndex: 'business_name',
      key: 'business_name',
    },
    {
      title: formatMessage({ id: 'text.viewDailyReport.subject' }),
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: formatMessage({ id: 'text.viewDailyReport.description' }),
      dataIndex: 'description',
      key: 'description',
      render: text => (
        <>
          <Tooltip placement="top" title={text}>
            <Button type="primary">View Message</Button>
          </Tooltip>
        </>
      ),
    },

    {
      title: formatMessage({ id: 'text.viewDailyReport.start_time' }),
      dataIndex: 'start_time',
      key: 'start_time',
      render: text => getFormattedDateWithTime(text),
    },
    {
      title: formatMessage({ id: 'text.viewDailyReport.end_time' }),
      dataIndex: 'end_time',
      key: 'end_time',
      render: text => getFormattedDateWithTime(text),
    },
    {
      title: formatMessage({ id: 'text.viewDailyReport.totalTime' }),
      dataIndex: 'hours',
      key: 'hours',
      render: text => secondsToHms(text),
    },
    {
      title: formatMessage({ id: 'text.viewDailyReport.created_at' }),
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => getFormattedDate(text),
    },

    {
      title: formatMessage({ id: 'text.viewDailyReport.username' }),
      dataIndex: 'username',
      key: 'username',
    },
  ]

  const path = window.location.pathname
  const userId = path.replace('/manage/accounts/', '')
  const dailyReportData = selectedDailyReport?.filter(data => data.id === userId)

  return (
    <div className="mb-4">
      <div className="row">
        <div className="col-md-4">
          <Form.Item>
            <Search
              style={{ width: '100%' }}
              placeholder={formatMessage({ id: 'form.user.placeholder.searchDailyReports' })}
              size="large"
              onChange={e => onSearch(e.target.value)}
              allowClear
            />
          </Form.Item>
        </div>
        {/* <div className="col-md-8 text-right">
          <button
            type="button"
            className="btn theme-btn-style"
            onClick={showCreate}
            style={{ float: 'right', marginTop: '2.5px' }}
          >
            <i className="fe fe-plus mr-1" aria-hidden="true" />
            {formatMessage({ id: 'action.createDailyReport' })}
          </button>
        </div> */}
      </div>
      <div className="kit__utils__table">
        <Suspense fallback={<Spin />}>
          {dailyReportData?.length >= 0 ? (
            <Table
              dataSource={dailyReportData}
              columns={columns}
              locale={locale}
              rowKey="_id"
              pagination={{
                onChange(current) {
                  setPage(current)
                },
              }}
            />
          ) : (
            <Table
              columns={columns}
              locale={locale}
              loading={{
                indicator: (
                  <div>
                    <Spin />
                  </div>
                ),
              }}
            />
          )}
        </Suspense>
      </div>
      {/* <Drawer
        title={formatMessage({ id: 'text.addDailyReport' })}
        placement="right"
        destroyOnClose
        visible={dailyreports?.displayAddDailyReportDrawer}
        onClose={hideCreate}
        width={settings?.isMobileView ? '100%' : '44%'}
      >
        <AddDailyReport loading={dailyreports?.creating} />
      </Drawer> */}
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(ViewDailyReports))
