import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loadleadfollowup() {
  return apiClient
    .get(`${BASE_URL}/getleadfollowupinfo`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadsalestargets() {
  return apiClient
    .get(`${BASE_URL}/loadsalestargets`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadpaymentprospect() {
  return apiClient
    .get(`${BASE_URL}/loadpaymentprospect`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadsalesprospect() {
  return apiClient
    .get(`${BASE_URL}/loadsalesprospect`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadsalesrevenue() {
  return apiClient
    .get(`${BASE_URL}/loadsalesrevenue`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filtersalesprospect(payload) {
  return apiClient
    .post(`${BASE_URL}/filtersalesprospect`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadservicefollowup() {
  return apiClient
    .get(`${BASE_URL}/getservicefollowupinfo`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadinvoicefollowup() {
  return apiClient
    .get(`${BASE_URL}/getinvoicefollowupinfo`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateleadfollowup(payload) {
  return apiClient
    .post(`${BASE_URL}/updateleadfollowup`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateservicefollowup(payload) {
  return apiClient
    .post(`${BASE_URL}/updateservicefollowup`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateinvoicefollowup(payload) {
  return apiClient
    .post(`${BASE_URL}/updateinvoicefollowup`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadaccountsfollowup(payload) {
  return apiClient
    .post(`${BASE_URL}/getaccountsfollowup`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateaccountsfollowup(payload) {
  return apiClient
    .post(`${BASE_URL}/updateaccountsfollowup`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
