/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  employeeName: null,
  userList: null,
  filterType: null,
  filterDetails: null,
  businessName: null,
  dailyReportData: null,
  dailyReportUserData: null,
  searchdailyreport: null,
  displayAddDailyReportDrawer: false,
  loading: false,
  creating: false,
  updating: false,
  removing: false,
}

export default function dailyreportsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_CREATE:
      return { ...state, displayAddDailyReportDrawer: true }
    case actions.HIDE_CREATE:
      return { ...state, displayAddDailyReportDrawer: false }

    default:
      return state
  }
}
