/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  domainListINfo: null,
  emailListINfo: null,
  domainnames: null,
  hostingListINfo: null,
  searchDomainname: null,
  searchhosting: null,
  searchemail: null,
  displayAddDomainNameDrawer: false,
  displayUpdateDomainNameDrawer: false,
  displayAddHostingDrawer: false,
  displayUpdateHostingDrawer: false,
  displayAddEmailDrawer: false,
  displayUpdateEmailDrawer: false,
  displayRemoveDomainModal: false,
  displayRemoveHostingModal: false,
  displayRemoveEmailModal: false,
  loading: false,
  creating: false,
  updating: false,
  removing: false,
}

export default function renewalsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_CREATE:
      return { ...state, displayAddDomainNameDrawer: true }
    case actions.HIDE_CREATE:
      return { ...state, displayAddDomainNameDrawer: false }
    case actions.SHOW_UPDATE:
      return {
        ...state,
        selectedData: action.payload.renewals,
        displayUpdateDomainNameDrawer: true,
      }
    case actions.HIDE_UPDATE:
      return { ...state, selectedData: null, displayUpdateDomainNameDrawer: false }

    case actions.SHOW_CREATE2:
      return { ...state, displayAddHostingDrawer: true }
    case actions.HIDE_CREATE2:
      return { ...state, displayAddHostingDrawer: false }
    case actions.SHOW_UPDATE2:
      return {
        ...state,
        selectedData: action.payload.renewals,
        displayUpdateHostingDrawer: true,
      }
    case actions.HIDE_UPDATE2:
      return { ...state, selectedData: null, displayUpdateHostingDrawer: false }
    case actions.SHOW_CREATE3:
      return { ...state, displayAddEmailDrawer: true }
    case actions.HIDE_CREATE3:
      return { ...state, displayAddEmailDrawer: false }
    case actions.SHOW_UPDATE3:
      return {
        ...state,
        selectedData: action.payload.renewals,
        displayUpdateEmailDrawer: true,
      }
    case actions.HIDE_UPDATE3:
      return { ...state, selectedData: null, displayUpdateEmailDrawer: false }
    case actions.SHOW_DELETE_DOMAIN:
      return { ...state, selectedData: action.payload.domain, displayRemoveDomainModal: true }
    case actions.HIDE_DELETE_DOMAIN:
      return { ...state, displayRemoveDomainModal: false }

    case actions.SHOW_DELETE_HOSTING:
      return { ...state, selectedData: action.payload.hosting, displayRemoveHostingModal: true }
    case actions.HIDE_DELETE_HOSTING:
      return { ...state, displayRemoveHostingModal: false }

    case actions.SHOW_DELETE_EMAIL:
      return { ...state, selectedData: action.payload.email, displayRemoveEmailModal: true }
    case actions.HIDE_DELETE_EMAIL:
      return { ...state, displayRemoveEmailModal: false }

    default:
      return state
  }
}
