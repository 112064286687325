import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loadallsms() {
  return apiClient
    .get(`${BASE_URL}/getallsms`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function sendsms(payload) {
  return apiClient
    .post(`${BASE_URL}/sendsms`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
