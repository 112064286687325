export default async function getMenuData() {
  return [
    {
      category: true,
      title: 'Leads',
    },
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'fe fe-settings',
      url: '/dashboard',
    },
    {
      title: 'My Profile',
      key: 'profile',
      icon: 'fe fe-user',
      url: '/profile',
    },
    {
      title: 'Manage Leads',
      key: 'manage-leads',
      icon: 'fe fe-user',
      url: '/manage/leads',
    },
    {
      title: 'Manage Accounts',
      key: 'manage-accounts',
      icon: 'fe fe-file',
      url: '/manage/accounts',
    },
    {
      title: 'Manage Users',
      key: 'manage-users',
      icon: 'fe fe-user',
      url: '/manage/user',
    },
    {
      title: 'Employee Management',
      key: 'manage-timeoff',
      icon: 'fe fe-clock',
      url: '/manage/timeoff',
    },
    {
      title: 'Manage Prospects',
      key: 'manage-prospects',
      icon: 'fe fe-user',
      url: '/manage/prospects',
    },
    {
      title: 'Manage Tasks',
      key: 'manage-tasks',
      icon: 'fe fe-settings',
      url: '/manage/tasks',
    },
    {
      title: 'Manage Invoices',
      key: 'manage-invoice',
      icon: 'fe fe-settings',
      url: '/manage/invoice',
    },
    {
      title: 'Manage Payments',
      key: 'manage-payments',
      icon: 'fe fe-settings',
      url: '/manage/payments',
    },
    {
      title: 'Manage Enquiry',
      key: 'manage-enquiry',
      icon: 'fe fe-globe',
      url: '/manage/enquiry',
    },
    {
      title: 'Manage Renewals',
      key: 'manage-renewals',
      icon: 'fe fe-calendar',
      url: '/manage/renewals',
    },
    {
      title: 'Manage Daily Reports',
      key: 'manage-daily-reports',
      icon: 'fe fe-file',
      url: '/manage/daily-reports',
    },
    {
      title: 'Manage Services',
      key: 'manage-services',
      icon: 'fe fe-calendar',
      url: '/manage/services',
    },
    {
      title: 'Third Party App',
      key: 'third-party-app',
      icon: 'fe fe-calendar',
      url: '/third-party-app',
    },
    {
      title: 'Notification',
      key: 'bulk-sms',
      icon: 'fe fe-calendar',
      url: '/manage/bulk-sms',
    },
  ]
}
