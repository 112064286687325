import { all, call, put, takeLatest } from 'redux-saga/effects'
import { loadallsms, sendsms } from 'api/sms'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadallsms)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        smsData: response?.data?.smsData,
      },
    })
  }
}

export function* SEND_SMS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(sendsms, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield call(LOAD_ALL)
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD_ALL, LOAD_ALL), takeLatest(actions.SEND_SMS, SEND_SMS)])
}
