import { all, call, put, takeLatest } from 'redux-saga/effects'
import { loadalldomainlist, syncdomain } from 'api/thirdparty'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadalldomainlist)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        DomainList: response?.data?.DomainList,
      },
    })
  }
}

export function* SYNC_DOMAIN() {
  const { response } = yield call(syncdomain)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        DomainList: response?.data?.DomainList,
        // displayFollowupDrawer: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD_ALL, LOAD_ALL), takeLatest(actions.SYNC_DOMAIN, SYNC_DOMAIN)])
}
