import { all, call, put, takeLatest } from 'redux-saga/effects'
import { loadallenquiry, sendemail, enquiryfilter, removeenquiry } from 'api/enquiry'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadallenquiry)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        enquiryData: response?.data?.enquiryinfo,
      },
    })
  }
}

export function* ENQUIRY_FILTER({ payload }) {
  const { response } = yield call(enquiryfilter, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        enquiryData: response?.data?.enquiryinfo,
      },
    })
  }
}

export function* REMOVE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })
  const { response } = yield call(removeenquiry, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        enquiryData: response?.data?.enquiryinfo,
        displayRemoveEnquiryModal: false,
        removing: false,
      },
    })
  }
}

export function* SEND_EMAIL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      sending: true,
    },
  })
  const { response } = yield call(sendemail, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      sending: false,
      displaySendEmailModal: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield call(LOAD_ALL)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.SEND_EMAIL, SEND_EMAIL),
    takeLatest(actions.ENQUIRY_FILTER, ENQUIRY_FILTER),
    takeLatest(actions.REMOVE, REMOVE),
  ])
}
