import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function login(payload) {
  return apiClient
    .post(`${BASE_URL}/check_login`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function recover(payload) {
  return apiClient
    .post(`${BASE_URL}/recover`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reset(payload) {
  return apiClient
    .post(`${BASE_URL}/reset`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function changepassword(updatedData, _id) {
  return apiClient
    .put(`${BASE_URL}/changepassword/${_id}`, updatedData)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadprofile(payload) {
  return apiClient
    .post(`${BASE_URL}/loadprofile`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}


export async function loadbank(payload) {
  return apiClient
    .post(`${BASE_URL}/loadbank`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateEmployeeBank(payload) {
  return apiClient
    .post(`${BASE_URL}/updateUsereBank`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

