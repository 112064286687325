import React, { useState, Suspense } from 'react'
import { connect, useDispatch } from 'react-redux'
import { injectIntl, useIntl } from 'react-intl'
import { Input, Table, Form, Spin, Badge } from 'antd'
import { createStructuredSelector } from 'reselect'
import { selectReceiptList, selectedReceiptList } from 'redux/receipt/selectors'
import { selectSettings } from 'redux/settings/selectors'
import { selectUser } from 'redux/user/selectors'
import { getFormattedDate } from 'utils/parser'
import ReceiptAction from 'redux/receipt/actions'

const { Search } = Input

const mapStateToProps = () =>
  createStructuredSelector({
    receipt: selectReceiptList,
    selectedReceipt: selectedReceiptList,
    settings: selectSettings,
    user: selectUser,
  })

const ViewServicesInfo = ({ selectedReceipt, intl: { formatMessage } }) => {
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const { formatNumber } = useIntl()

  const onSearch = value => {
    dispatch({
      type: ReceiptAction.SET_STATE,
      payload: { searchreceipt: value },
    })
  }

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.servicesData' }),
  }

  const columns = [
    {
      title: formatMessage({ id: 'text.viewservicesData.sno' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.viewservicesData.business_name' }),
      dataIndex: 'business_name',
      key: 'business_name',
    },
    {
      title: formatMessage({ id: 'text.viewservicesData.advance_payment' }),
      dataIndex: 'advance_payment',
      key: 'advance_payment',
      render: record => {
        return (
          <span>
            {formatNumber(record, {
              style: 'currency',
              currency: 'INR',
            })}
          </span>
        )
      },
    },
    {
      title: formatMessage({ id: 'text.viewservicesData.payment_mode' }),
      dataIndex: 'payment_mode',
      key: 'payment_mode',
      render: text => (
        <>
          {text === 'NEFT' && (
            <>
              <Badge color="green" text={formatMessage({ id: 'text.NEFT' })} />
            </>
          )}
          {text === 'IMPS' && (
            <>
              <Badge color="blue" text={formatMessage({ id: 'text.IMPS' })} />
            </>
          )}
          {text === 'RTGS' && (
            <>
              <Badge color="orange" text={formatMessage({ id: 'text.RTGS' })} />
            </>
          )}
          {text === 'GPAY' && (
            <>
              <Badge color="yellow" text={formatMessage({ id: 'text.GPAY' })} />
            </>
          )}
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.viewservicesData.payment_date' }),
      dataIndex: 'payment_date',
      key: 'payment_date',
      render: text => getFormattedDate(text),
    },
  ]

  const path = window.location.pathname
  const userId = path.replace('/manage/accounts/', '')

  const receiptdata = selectedReceipt?.filter(data => data.accounts_id === userId)

  return (
    <div className="mb-4">
      <div className="row">
        <div className="col-md-4">
          <Form.Item>
            <Search
              style={{ width: '100%' }}
              placeholder={formatMessage({ id: 'form.user.placeholder.searchReceipt' })}
              size="large"
              onChange={e => onSearch(e.target.value)}
              allowClear
            />
          </Form.Item>
        </div>
      </div>
      <div className="kit__utils__table">
        <Suspense fallback={<Spin />}>
          {receiptdata?.length >= 0 ? (
            <Table
              dataSource={receiptdata}
              columns={columns}
              locale={locale}
              rowKey="_id"
              pagination={{
                onChange(current) {
                  setPage(current)
                },
              }}
            />
          ) : (
            <Table
              columns={columns}
              locale={locale}
              loading={{
                indicator: (
                  <div>
                    <Spin />
                  </div>
                ),
              }}
            />
          )}
        </Suspense>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(ViewServicesInfo))
