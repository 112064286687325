import React, { useState, Suspense } from 'react'
import { connect } from 'react-redux'
import { injectIntl, useIntl } from 'react-intl'
import { createStructuredSelector } from 'reselect'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Card, Col, Row, Statistic, Spin, Table } from 'antd'
import { selectReceiptList, selectedReceiptList } from 'redux/receipt/selectors'
import { selectSettings } from 'redux/settings/selectors'
import { selectUser } from 'redux/user/selectors'
import { getFormattedDateWithTime } from 'utils/parser'

const mapStateToProps = () =>
  createStructuredSelector({
    receipt: selectReceiptList,
    selectedReceipt: selectedReceiptList,
    settings: selectSettings,
    user: selectUser,
  })

const ViewServicesInfo = ({ intl: { formatMessage }, apiRes, totalCreditRes }) => {
  const [page, setPage] = useState(1)
  const { formatNumber } = useIntl()

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.LedgerData' }),
  }

  const columns = [
    {
      title: formatMessage({ id: 'text.viewLedger.sno' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.viewLedger.month_year' }),
      dataIndex: 'month_year',
      key: 'month_year',
    },
    {
      title: formatMessage({ id: 'text.viewLedger.payment_date' }),
      dataIndex: 'created_date',
      key: 'created_date',
      render: text => getFormattedDateWithTime(text),
    },
    {
      title: formatMessage({ id: 'text.viewLedger.business_name' }),
      render: record => {
        return <>{`${record?.business_name} -> AMPO${record?.proforma_number}`}</>
      },
    },
    {
      title: formatMessage({ id: 'text.viewLedger.debit' }),
      dataIndex: 'debit_balance',
      key: 'debit_balance',
      render: record => {
        return (
          <>
            {record !== '0' && (
              <>
                <center>
                  <span style={{ color: 'red', fontWeight: 500 }}>
                    {formatNumber(record, {
                      style: 'currency',
                      currency: 'INR',
                    })}
                  </span>{' '}
                  Dr
                </center>
              </>
            )}
            {record === '0' && (
              <>
                <center>
                  <span style={{ color: 'black', fontWeight: 500 }}>-</span>
                </center>
              </>
            )}
          </>
        )
      },
    },
    {
      title: formatMessage({ id: 'text.viewLedger.credit' }),
      dataIndex: 'credit_balance',
      key: 'credit_balance',
      render: record => {
        return (
          <>
            {record !== '0' && (
              <>
                <center>
                  <span style={{ color: 'green', fontWeight: 500 }}>
                    {formatNumber(record, {
                      style: 'currency',
                      currency: 'INR',
                    })}
                  </span>{' '}
                  Cr
                </center>
              </>
            )}
            {record === '0' && (
              <>
                <center>
                  <span style={{ color: 'black', fontWeight: 500 }}>-</span>
                </center>
              </>
            )}
          </>
        )
      },
    },
  ]

  const totalCredit = totalCreditRes

  const totalBillAmount = totalCredit?.debit
  const totalReceived = totalCredit?.credit
  const totalBalance = totalCredit?.balance

  return (
    <div className="mb-4">
      <div className="row">
        <div className="col-lg-12">
          <Row gutter={16}>
            <Col span={8}>
              <Card bordered={false} style={{ backgroundColor: '#000000' }}>
                <Statistic
                  title="Total Bill Amount"
                  value={totalBillAmount}
                  precision={2}
                  valueStyle={{
                    color: '#ffffff',
                  }}
                  prefix={<ArrowUpOutlined />}
                  suffix="INR"
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered={false} style={{ backgroundColor: '#008000' }}>
                <Statistic
                  title="Total Received"
                  value={totalReceived}
                  precision={2}
                  valueStyle={{
                    color: '#ffffff',
                  }}
                  prefix={<ArrowUpOutlined />}
                  suffix="INR"
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered={false} style={{ backgroundColor: '#f5222e' }}>
                <Statistic
                  title="Balance Amount"
                  value={totalBalance}
                  precision={2}
                  valueStyle={{
                    color: '#ffffff',
                  }}
                  prefix={<ArrowDownOutlined />}
                  suffix="INR"
                />
              </Card>
            </Col>
          </Row>
        </div>
        <div className="col-lg-12 mt-5">
          <div className="kit__utils__table">
            <Suspense fallback={<Spin />}>
              {apiRes?.length >= 0 ? (
                <Table
                  dataSource={apiRes}
                  columns={columns}
                  locale={locale}
                  rowKey="_id"
                  pagination={{
                    onChange(current) {
                      setPage(current)
                    },
                  }}
                />
              ) : (
                <Table
                  columns={columns}
                  locale={locale}
                  loading={{
                    indicator: (
                      <div>
                        <Spin />
                      </div>
                    ),
                  }}
                />
              )}
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(ViewServicesInfo))
