import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Form, Button, InputNumber } from 'antd'
import TimeOffAction from 'redux/timeoff/actions'

const mapStateToProps = ({ dispatch, timeOff }) => ({
  dispatch,
  timeOff,
})

const StoreSettings = ({ timeOff, intl: { formatMessage }, dispatch }) => {
  const path = window.location.pathname

  const userId = path.replace('/manage/employee/', '')

  useEffect(() => {
    dispatch({
      type: TimeOffAction.CHECK_PAYROLL,
      payload: {
        employeeId: userId,
      },
    })
  }, [dispatch, userId])

  const [form] = Form.useForm()

  const [showCalculation, setshowCalculation] = useState(false)
  const [disabledField, setdisabledField] = useState(false)

  const CalculatePayroll = () => {
    const res = form.getFieldValue()
    setshowCalculation(true)
    dispatch({
      type: TimeOffAction.CHECK_CALCULATION,
      payload: {
        salaryAmount: res?.monthly_salary,
      },
    })
  }

  useEffect(() => {
    if (timeOff?.isShowPayroll) {
      setshowCalculation(true)
      setdisabledField(false)
    } else {
      setdisabledField(true)
    } // eslint-disable-next-line
  }, [form, timeOff?.isShowPayroll])

  useEffect(() => {
    if (timeOff?.calculationInfo?.basicPayAmount) {
      const ResInfo = timeOff?.calculationInfo
      form.setFieldsValue({
        basic_pay: ResInfo?.basicPayAmount,
        hra: ResInfo?.hraAmount,
        special_allowance: ResInfo?.specialAllowance,
        medical_allowance: ResInfo?.medicalAllowanceAmount,
        bonus: ResInfo?.bonusAmount,
      })
    } // eslint-disable-next-line
  }, [form, timeOff?.calculationInfo?.basicPayAmount])

  let apiResponse = []

  if (timeOff?.isShowPayroll) {
    apiResponse = timeOff?.payrollInformation
  } else {
    apiResponse = timeOff?.calculationInfo
  }

  useEffect(() => {
    if (apiResponse) {
      form.setFieldsValue({
        monthly_salary: apiResponse?.monthly_salary,
        basic_pay: apiResponse?.basicPayAmount,
        hra: apiResponse?.hraAmount,
        special_allowance: apiResponse?.specialAllowance,
        medical_allowance: apiResponse?.medicalAllowanceAmount,
        bonus: apiResponse?.bonusAmount,
      })
    } // eslint-disable-next-line
  }, [form, apiResponse])

  const onFinish = values => {
    dispatch({
      type: TimeOffAction.UPDATE_PAYROLL_CONFIG,
      payload: {
        employeeId: userId,
        updatedData: values,
      },
    })
  }

  console.log('isShowPayroll', timeOff?.isShowPayroll)
  console.log('timeOff', timeOff)
  console.log('apiResponse', apiResponse)

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <Form
            form={form}
            className="mb-4"
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              basic_pay: apiResponse?.basicPayAmount,
              hra: apiResponse?.hraAmount,
              special_allowance: apiResponse?.specialAllowance,
              medical_allowance: apiResponse?.medicalAllowanceAmount,
              bonus: apiResponse?.bonusAmount,
            }}
            hideRequiredMark
          >
            <div className="row">
              <div className="col-md-3">
                <Form.Item
                  name="monthly_salary"
                  label={formatMessage({ id: 'form.label.monthly_salary' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <InputNumber
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.monthly_salary' })}
                    style={{ width: '300px' }}
                  />
                </Form.Item>
                {timeOff?.isShowPayroll === false && (
                  <>
                    <Button type="primary" size="small" onClick={() => CalculatePayroll()}>
                      <strong>{formatMessage({ id: 'action.calculate' })}</strong>
                    </Button>
                  </>
                )}
              </div>

              {showCalculation && (
                <>
                  <div className="col-md-3">
                    <Form.Item
                      name="basic_pay"
                      label={formatMessage({ id: 'form.label.basic_pay' })}
                      rules={[
                        { required: true, message: formatMessage({ id: 'form.error.required' }) },
                      ]}
                    >
                      <InputNumber
                        size="large"
                        placeholder={formatMessage({ id: 'form.placeholder.basic_pay' })}
                        style={{ width: '300px' }}
                        disabled={disabledField}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-3">
                    <Form.Item
                      name="hra"
                      label={formatMessage({ id: 'form.label.hra' })}
                      rules={[
                        { required: true, message: formatMessage({ id: 'form.error.required' }) },
                      ]}
                    >
                      <InputNumber
                        size="large"
                        placeholder={formatMessage({ id: 'form.placeholder.hra' })}
                        style={{ width: '300px' }}
                        disabled={disabledField}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-3">
                    <Form.Item
                      name="special_allowance"
                      label={formatMessage({ id: 'form.label.special_allowance' })}
                      rules={[
                        { required: true, message: formatMessage({ id: 'form.error.required' }) },
                      ]}
                    >
                      <InputNumber
                        size="large"
                        placeholder={formatMessage({ id: 'form.placeholder.special_allowance' })}
                        style={{ width: '300px' }}
                        disabled={disabledField}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-3">
                    <Form.Item
                      name="medical_allowance"
                      label={formatMessage({ id: 'form.label.medical_allowance' })}
                      rules={[
                        { required: true, message: formatMessage({ id: 'form.error.required' }) },
                      ]}
                    >
                      <InputNumber
                        size="large"
                        placeholder={formatMessage({ id: 'form.placeholder.medical_allowance' })}
                        style={{ width: '300px' }}
                        disabled={disabledField}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-3">
                    <Form.Item
                      name="bonus"
                      label={formatMessage({ id: 'form.label.bonus' })}
                      rules={[
                        { required: true, message: formatMessage({ id: 'form.error.required' }) },
                      ]}
                    >
                      <InputNumber
                        size="large"
                        placeholder={formatMessage({ id: 'form.placeholder.bonus' })}
                        style={{ width: '300px' }}
                        disabled={disabledField}
                      />
                    </Form.Item>
                  </div>
                </>
              )}
              <div className="col-md-12" hidden={timeOff?.isShowPayroll}>
                <Form.Item className="text-right">
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    loading={timeOff?.updatingPayroll}
                  >
                    <strong>{formatMessage({ id: 'action.update' })}</strong>
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(StoreSettings))
