const actions = {
  SET_STATE: 'leads/SET_STATE',
  SHOW_CREATE: 'leads/SHOW_CREATE',
  HIDE_CREATE: 'leads/HIDE_CREATE',
  SHOW_UPDATE: 'leads/SHOW_UPDATE',
  HIDE_UPDATE: 'leads/HIDE_UPDATE',
  SHOW_DELETE: 'leads/SHOW_DELETE',
  HIDE_DELETE: 'leads/HIDE_DELETE',
  SHOW_LEAD_OWNER: 'leads/SHOW_LEAD_OWNER',
  HIDE_LEAD_OWNER: 'leads/HIDE_LEAD_OWNER',
  GET_LEAD_OWNERS: 'leads/GET_LEAD_OWNERS',
  CHANGE_LEAD_OWNER: 'leads/CHANGE_LEAD_OWNER',
  REMOVE: 'leads/REMOVE',
  SHOW_NOTES: 'leads/SHOW_NOTES',
  SHOW_FOLLOWUP: 'leads/SHOW_FOLLOWUP',
  HIDE_NOTES: 'leads/HIDE_NOTES',
  HIDE_FOLLOWUP: 'leads/HIDE_FOLLOWUP',
  GETLEADNOTES: 'leads/GETLEADNOTES',
  SAVELEADNOTE: 'leads/SAVELEADNOTE',
  SHOW_ACCOUNTS_CONVERT: 'leads/SHOW_ACCOUNTS_CONVERT',
  HIDE_ACCOUNTS_CONVERT: 'leads/HIDE_ACCOUNTS_CONVERT',
  CONVERT_ACCOUNTS: 'leads/CONVERT_ACCOUNTS',
  LEAD_FILTER: 'leads/LEAD_FILTER',

  ADD_LEAD_FOLLOWUP: 'leads/ADD_LEAD_FOLLOWUP',
  LOAD_ALL: 'leads/LOAD_ALL',
  ADD_LEADS: 'leads/ADD_LEADS',
  UPDATE_LEADS: 'leads/UPDATE_LEADS',
  GETFILTER: 'leads/GETFILTER',
}

export default actions
