import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loadalltasks() {
  return apiClient
    .get(`${BASE_URL}/getalltasks`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getuserlist() {
  return apiClient
    .get(`${BASE_URL}/getuserprofile`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function gettaskcategory() {
  return apiClient
    .get(`${BASE_URL}/gettaskcategory`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function gettasktype() {
  return apiClient
    .get(`${BASE_URL}/gettasktype`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function savetask(payload) {
  return apiClient
    .post(`${BASE_URL}/createTasks`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updatetask(payload) {
  return apiClient
    .post(`${BASE_URL}/updatetask`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadaccountnotes(payload) {
  return apiClient
    .get(`${BASE_URL}/gettaskhistory/${payload}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function savetasknotes(payload) {
  return apiClient
    .post(`${BASE_URL}/savetasknotes`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateduedate(payload) {
  return apiClient
    .post(`${BASE_URL}/updateduedate`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updatetaskstatus(payload) {
  return apiClient
    .post(`${BASE_URL}/updatetaskstatus`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadfilter(payload) {
  return apiClient
    .post(`${BASE_URL}/loadfilter`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
