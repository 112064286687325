/* eslint no-plusplus: "error" */

import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { InputNumber, Form, Button, DatePicker } from 'antd'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import TimeOffAction from 'redux/timeoff/actions'

const mapStateToProps = ({ dispatch, user, accounts, timeOff }) => ({
  dispatch,
  accounts,
  timeOff,
  user,
})

const CreateTaskInfo = ({ userId, timeOff, intl: { formatMessage } }) => {
  const dispatch = useDispatch()

  const onFinish = values => {
    dispatch({
      type: TimeOffAction.GENERATE_PAYSLIP,
      payload: {
        monthfor: values?.monthfor.format('YYYY-MM'),
        total_working_days: values?.total_working_days,
        leave_days: values?.leave_days,
        cl_adjusted_days: values?.cl_adjusted_days,
        lop_days: values?.lop_days,
        advance_deductions: values?.advance_deductions,
        employeeId: userId,
      },
    })
  }

  const [form] = Form.useForm()

  const monthFormat = 'YYYY/MM'

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <Form form={form} className="mb-4" layout="vertical" onFinish={onFinish} hideRequiredMark>
            <div className="row">
              <div className="col-md-6">
                <Form.Item
                  name="monthfor"
                  label={formatMessage({ id: 'form.label.monthfor' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <DatePicker
                    disabledDate={current => current.isAfter(moment().subtract(1, 'day'))}
                    format={monthFormat}
                    picker="month"
                    style={{ width: '250px' }}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="total_working_days"
                  label={formatMessage({ id: 'form.label.total_working_days' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <InputNumber
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.total_working_days' })}
                    style={{ width: '300px' }}
                  />
                </Form.Item>
              </div>

              <div className="col-md-6">
                <Form.Item
                  name="leave_days"
                  label={formatMessage({ id: 'form.label.leave_days' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <InputNumber
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.leave_days' })}
                    style={{ width: '300px' }}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="cl_adjusted_days"
                  label={formatMessage({ id: 'form.label.cl_adjusted_days' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <InputNumber
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.cl_adjusted_days' })}
                    style={{ width: '300px' }}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="lop_days"
                  label={formatMessage({ id: 'form.label.lop_days' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <InputNumber
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.lop_days' })}
                    style={{ width: '300px' }}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="advance_deductions"
                  label={formatMessage({ id: 'form.label.advance_deductions' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <InputNumber
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.advance_deductions' })}
                    style={{ width: '300px' }}
                  />
                </Form.Item>
              </div>
              {/* <div className="col-md-6">

              </div>
              <div className="col-md-6">

              </div> */}
            </div>

            <div className="row mt-1">
              <div className="col-md-12">
                <Form.Item className="text-right">
                  <Button type="primary" htmlType="submit" size="large" loading={timeOff?.creating}>
                    <strong>{formatMessage({ id: 'action.create' })}</strong>
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(CreateTaskInfo))
