import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import IndividualAccountsAction from 'redux/singleaccounts/actions'
import { Typography } from 'antd'
import ViewLedger from './ViewLedger'

const { Text } = Typography

const mapStateToProps = ({ dispatch, singleaccounts }) => ({
  dispatch,
  singleaccounts,
})

const ReciptsComp = ({ singleaccounts, intl: { formatMessage } }) => {
  const dispatch = useDispatch()

  const path = window.location.pathname
  const userId = path.replace('/manage/accounts/', '')

  useEffect(() => {
    dispatch({
      type: IndividualAccountsAction.LOAD_LEDGER,
      payload: {
        accountsId: userId,
      },
    })
  }, [dispatch, userId])

  const ledgerData = singleaccounts?.ledgerInfo

  const ledgerApiRes = ledgerData?.filter(data => data.accounts_id === userId)

  const ledgerCount = ledgerApiRes?.length || 0

  return (
    <div>
      <Helmet title={formatMessage({ id: 'title.helmet.services' })} />
      <div className="card">
        <div className="card-body">
          <h5 className="mb-4">
            <strong>{formatMessage({ id: 'text.ledgerList' })}</strong>
            &nbsp;&nbsp;
            <Text className="font-size-16 font-weight-bold" code>
              {formatMessage({ id: 'text.totalCount' }, { count: ledgerCount })}
            </Text>
          </h5>
          <ViewLedger apiRes={ledgerApiRes} totalCreditRes={singleaccounts?.totalCredit} />
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(ReciptsComp))
