/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  IndividualAccountData: null,
  ledgerInfo: null,
  totalCredit: null,
  loading: false,
  creating: false,
  updating: false,
  removing: false,
  displayUpdateAccountsDrawer: false,
}

export default function singleaccountsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_UPDATE:
      return { ...state, selectedData: action.payload.account, displayUpdateAccountsDrawer: true }
    case actions.HIDE_UPDATE:
      return { ...state, selectedData: null, displayUpdateAccountsDrawer: false }
    case actions.SHOW_NOTES:
      return { ...state, selectedData: action.payload.account, displayAccountsNotesDrawer: true }
    case actions.HIDE_NOTES:
      return { ...state, selectedData: null, displayAccountsNotesDrawer: false }
    case actions.SHOW_ACCOUNT_MANAGER:
      return { ...state, selectedData: action.payload.account, displayAccountManagerModal: true }
    case actions.HIDE_ACCOUNT_MANAGER:
      return { ...state, selectedData: null, displayAccountManagerModal: false }
    case actions.SHOW_ASSIGNEDTO:
      return { ...state, selectedData: action.payload.account, displayAccountAssignedtoModal: true }
    case actions.HIDE_ASSIGNEDTO:
      return { ...state, selectedData: null, displayAccountAssignedtoModal: false }
    default:
      return state
  }
}
