import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  loadalldailyreports,
  savedailyreport,
  loaduser,
  filterdailyreports,
  filterBy
} from 'api/dailyreports'
import { getuserlist } from "api/timeoff"
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadalldailyreports)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        dailyReportData: response?.data?.dailyreportInfo,
      },
    })
  }
}

export function* GETFILTER({ payload }) {
  const { response } = yield call(filterdailyreports, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        dailyReportData: response?.data?.dailyreportInfo,
      },
    })
  }
}


export function* FILTER_REPORT({ payload }) {
  const { response } = yield call(filterBy, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        filterInfo: response?.data?.filterInfo,
        businessName: response?.data?.businessName,
        employeeName: response?.data?.employeeName,
        filterDetails: response?.data?.filterDetails,
        filterType: response?.data?.filterType,
      },
    })
  }
}


export function* GETUSERLIST() {
  const { response } = yield call(getuserlist)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        userList: response?.data?.userList,
      },
    })
  }
}

export function* LOAD_DAILY_REPORT_USER() {
  const { response } = yield call(loaduser)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        dailyReportUserData: response?.data?.userInfo,
      },
    })
  }
}

export function* ADD_DAILY_REPORTS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(savedailyreport, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield call(LOAD_ALL)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GETUSERLIST, GETUSERLIST),
    takeLatest(actions.FILTER_REPORT, FILTER_REPORT),
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.GETFILTER, GETFILTER),
    takeLatest(actions.LOAD_DAILY_REPORT_USER, LOAD_DAILY_REPORT_USER),
    takeLatest(actions.ADD_DAILY_REPORTS, ADD_DAILY_REPORTS),
  ])
}
