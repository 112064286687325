import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  loadallaccounts,
  updateaccounts,
  loadaccountnotes,
  saveaccountsnotes,
  changeaccountmanager,
  changeaccountsassignedto,
  addaccountsfollowup,
  loadallAccountsFilter,
} from 'api/accounts'
import { loadleadowners } from 'api/lead'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadallaccounts)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        accountlistinfo: response?.data?.accountlist,
      },
    })
  }
}

export function* ACCOUNTS_FILTER({ payload }) {
  const { response } = yield call(loadallAccountsFilter, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        accountlistinfo: response?.data?.accountlist,
      },
    })
  }
}

export function* ADD_ACCOUNTS_FOLLOWUP({ payload }) {
  const { response } = yield call(addaccountsfollowup, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        accountsfollowupinfo: response?.data?.accountsfollowupinfo,
        displayFollowupDrawer: false,
      },
    })
  }
}

export function* UPDATE_ACCOUNTS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { _id, updatedData } = payload
  const { response } = yield call(updateaccounts, _id, updatedData)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_UPDATE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        accountlistinfo: response?.data?.accountlist,
      },
    })
  }
}

export function* GETACCOUNTSNOTES({ payload }) {
  const { response } = yield call(loadaccountnotes, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        notesInfo: response?.data?.accountnotesInfo,
      },
    })
  }
}

export function* SAVEACCOUNTNOTES({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(saveaccountsnotes, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_NOTES,
    })
  }
}

export function* GET_LEAD_OWNERS() {
  const { response } = yield call(loadleadowners)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        leadOwnerData: response?.data?.leadownerslist,
      },
    })
  }
}

export function* CHANGE_ACCOUNT_MANAGER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(changeaccountmanager, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_ACCOUNT_MANAGER,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        accountlistinfo: response?.data?.accountlist,
      },
    })
  }
}

export function* CHANGE_ACCOUNTS_ASSIGNED_TO({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(changeaccountsassignedto, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_ASSIGNEDTO,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        accountlistinfo: response?.data?.accountlist,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.UPDATE_ACCOUNTS, UPDATE_ACCOUNTS),
    takeLatest(actions.GETACCOUNTSNOTES, GETACCOUNTSNOTES),
    takeLatest(actions.SAVEACCOUNTNOTES, SAVEACCOUNTNOTES),
    takeLatest(actions.GET_LEAD_OWNERS, GET_LEAD_OWNERS),
    takeLatest(actions.CHANGE_ACCOUNT_MANAGER, CHANGE_ACCOUNT_MANAGER),
    takeLatest(actions.CHANGE_ACCOUNTS_ASSIGNED_TO, CHANGE_ACCOUNTS_ASSIGNED_TO),
    takeLatest(actions.ADD_ACCOUNTS_FOLLOWUP, ADD_ACCOUNTS_FOLLOWUP),
    takeLatest(actions.ACCOUNTS_FILTER, ACCOUNTS_FILTER),
  ])
}
