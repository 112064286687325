import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  loadallservices,
  saveserviceinfo,
  changeservicevalidity,
  addservicefollowup,
} from 'api/services'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadallservices)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        servicesData: response?.data?.servicesInfo,
      },
    })
  }
}

export function* ADD_SERVICES({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(saveserviceinfo, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield call(LOAD_ALL)
  }
}
export function* CHANGE_SERVICE_VALIDITY({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(changeservicevalidity, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
      displayChangeValidityDrawer: false,
    },
  })
  if (response) {
    yield call(LOAD_ALL)
  }
}
export function* ADD_SERVICE_FOLLOWUP({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      adding: true,
    },
  })
  const { response } = yield call(addservicefollowup, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      adding: false,
      displayFollowupDrawer: false,
    },
  })
  if (response) {
    yield call(LOAD_ALL)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.ADD_SERVICES, ADD_SERVICES),
    takeLatest(actions.CHANGE_SERVICE_VALIDITY, CHANGE_SERVICE_VALIDITY),
    takeLatest(actions.ADD_SERVICE_FOLLOWUP, ADD_SERVICE_FOLLOWUP),
  ])
}
