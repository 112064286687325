const actions = {
  SET_STATE: 'tasks/SET_STATE',
  LOAD_ALL: 'tasks/LOAD_ALL',
  GETUSERLIST: 'tasks/GETUSERLIST',
  SHOW_CREATE: 'tasks/SHOW_CREATE',
  HIDE_CREATE: 'tasks/HIDE_CREATE',
  SHOW_UPDATE: 'tasks/SHOW_UPDATE',
  HIDE_UPDATE: 'tasks/HIDE_UPDATE',
  GETTASKCATEGORY: 'tasks/GETTASKCATEGORY',
  GETTASKTYPE: 'tasks/GETTASKTYPE',
  ADD_TASKS: 'tasks/ADD_TASKS',
  GETNOTES: 'tasks/GETNOTES',
  SAVENOTE: 'tasks/SAVENOTE',
  SHOW_ASSIGNEDTO: 'tasks/SHOW_ASSIGNEDTO',
  HIDE_ASSIGNEDTO: 'tasks/HIDE_ASSIGNEDTO',
  UPDATE_TASK: 'tasks/UPDATE_TASK',
  SHOW_DUE_DATE_UPDATE: 'tasks/SHOW_DUE_DATE_UPDATE',
  HIDE_DUE_DATE_UPDATE: 'tasks/HIDE_DUE_DATE_UPDATE',
  UPDATE_DUEDATE: 'tasks/UPDATE_DUEDATE',
  CHANGE_TASK_STATUS: 'tasks/CHANGE_TASK_STATUS',
  GETFILTER: 'tasks/GETFILTER',
}

export default actions
