import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { history } from 'index'
import { login, changepassword, loadprofile,loadbank,updateEmployeeBank } from 'api/user'
import store from 'store'
import RootAction from 'redux/actions'
import actions from './actions'
import menuactions from '../menu/actions'
import { userStateData } from './reducers'

export function* LOGIN({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitting: true,
    },
  })
  const { response } = yield call(login, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitting: false,
    },
  })
  if (response) {
    yield call(LOGIN_USER, response)
  }
}

export function* CHANGE_PASSWORD({ payload }) {
  const { updatedData, _id } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      changing: true,
    },
  })
  const { response } = yield call(changepassword, updatedData, _id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      changing: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_PASSWORD_MODAL,
    })
  }
}

function* LOGIN_USER(response) {
  const accessToken = response?.data?.token
  const userName = response?.data?.username
  const name = response?.data?.name
  const userRole = response?.data?.role
  const lastLogin = response?.data?.lastlogin
  const userId = response?.data?.userId
  const LogginedUserId = response?.data?._id
  store.set(`user.accessToken`, response?.data?.token)
  store.set(`user.userName`, response?.data?.username)
  store.set(`user.userRole`, response?.data?.role)
  store.set(`user.lastLogin`, response?.data?.lastlogin)
  store.set(`user.userId`, response?.data?.userId)
  store.set(`user.name`, response?.data?.name)
  store.set(`user._id`, response?.data?._id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      accessToken,
      userName,
      userRole,
      lastLogin,
      userId,
      name,
      LogginedUserId,
    },
  })
  yield put({
    type: menuactions.SET_DATA,
  })
  yield history.push('/')
}

export function* LOGOUT() {
  yield call(CLEAR_USER)
}

export function* CLEAR_USER() {
  store.clearAll()
  yield put({
    type: RootAction.CLEAR_STATE,
  })
  yield put({
    type: actions.SET_STATE,
    payload: userStateData,
  })
  yield history.push('/')
}

export function* GET_MY_PROFILE({ payload }) {
  const { response } = yield call(loadprofile, payload)
  if (response) {
    const { myProfile } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        myProfileInfo: myProfile[0],
      },
    })
  }
}

export function* GET_MY_BANK_INFO({ payload }) {
  const { response } = yield call(loadbank, payload)
  if (response) {
    const { myBank } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        myBankInfo: myBank[0],
      },
    })
  }
}

export function* UPDATE_BANK_DETAILS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(updateEmployeeBank, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        updating: false,
        myBankInfo: response?.data?.myBankInfo[0],
      },
    })
  }
}


export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeLatest(actions.CLEAR_USER, CLEAR_USER),
    takeLatest(actions.CHANGE_PASSWORD, CHANGE_PASSWORD),
    takeLatest(actions.GET_MY_PROFILE, GET_MY_PROFILE),
    takeLatest(actions.GET_MY_BANK_INFO, GET_MY_BANK_INFO),
    takeLatest(actions.UPDATE_BANK_DETAILS, UPDATE_BANK_DETAILS),
  ])
}