import React, { useState, useEffect, Suspense } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import RenewalsAction from 'redux/renewals/actions'
import { createStructuredSelector } from 'reselect'
import { getFormattedDate } from 'utils/parser'
import {
  selectDomainNameList,
  selectedDomainNameList,
  selectedHostingList,
  SelectedEmailList,
} from 'redux/renewals/selectors'
import { selectSettings } from 'redux/settings/selectors'
import { Input, Table, Form, Tag, Spin, Drawer } from 'antd'
import AddEmail from '../AddEmail'
import UpdateEmail from '../UpdateEmail'

const { Search } = Input

const mapStateToProps = () =>
  createStructuredSelector({
    email: selectDomainNameList,
    selectedDomain: selectedDomainNameList,
    selectedHosting: selectedHostingList,
    selectedEmail: SelectedEmailList,
    settings: selectSettings,
  })

const ActiveStoreInfo = ({ dispatch, email, selectedEmail, settings, intl: { formatMessage } }) => {
  const [page, setPage] = useState(1)

  useEffect(() => {
    dispatch({
      type: RenewalsAction.LOAD_EMAIL_LIST,
    })
  }, [dispatch])

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.EmailList' }),
  }

  const onSearch = value => {
    dispatch({
      type: RenewalsAction.SET_STATE,
      payload: { searchemail: value },
    })
  }

  const showCreate = () => {
    dispatch({
      type: RenewalsAction.SHOW_CREATE3,
    })
  }

  const hideCreate = () => {
    dispatch({
      type: RenewalsAction.HIDE_CREATE3,
    })
  }

  const showUpdate = values => {
    dispatch({
      type: RenewalsAction.SHOW_UPDATE3,
      payload: {
        renewals: values,
      },
    })
  }

  const hideUpdate = () => {
    dispatch({
      type: RenewalsAction.HIDE_UPDATE3,
    })
  }

  const columns = [
    {
      title: formatMessage({ id: 'text.active.store.sno' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}.`,
    },

    {
      title: formatMessage({ id: 'text.domainname' }),
      dataIndex: 'domainname',
      key: 'domainname',
      render: (text, record) => (
        <>
          {text}
          {'     '}
          {record?.status === '1' && (
            <>
              <Tag color="green">{formatMessage({ id: 'text.active' })}</Tag>
            </>
          )}
          {record?.status === '0' && (
            <>
              <Tag color="red">{formatMessage({ id: 'text.Inactive' })}</Tag>
            </>
          )}
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.business_name' }),
      dataIndex: 'business_name',
      key: 'business_name',
    },
    {
      title: formatMessage({ id: 'text.emailType' }),
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => (
        <>
          {record.emailid} - ({text})
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.validity' }),
      dataIndex: 'validity',
      key: 'validity',
      render: text => <>{text} Days Remaining</>,
    },
    {
      title: formatMessage({ id: 'text.registered_date' }),
      dataIndex: 'registered_date',
      key: 'registered_date',
      render: text => getFormattedDate(text),
    },
    {
      title: formatMessage({ id: 'text.expiry_date' }),
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      render: text => getFormattedDate(text),
    },
    {
      title: formatMessage({ id: 'text.action' }),
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => (
        <>
          <button
            type="button"
            className="btn btn-primary"
            title="Update Domain"
            onClick={() => showUpdate(record)}
          >
            <i className="fe fe-edit mr-1" aria-hidden />
            {formatMessage({ id: 'action.edit' })}
          </button>
        </>
      ),
    },
  ]

  const path = window.location.pathname
  const userId = path.replace('/manage/accounts/', '')

  const emailrenewalData = selectedEmail?.filter(data => data.accounts_id === userId)

  return (
    <div>
      <div className="row">
        <div className="col-md-4">
          <Form.Item>
            <Search
              style={{ width: '100%', height: '100%' }}
              placeholder={formatMessage({ id: 'text.email.search' })}
              size="large"
              onSearch={onSearch}
              defaultValue={emailrenewalData?.searchemail}
              allowClear
            />
          </Form.Item>
        </div>
        <div className="col-md-8 text-right">
          <button
            type="button"
            className="btn theme-btn-style"
            onClick={showCreate}
            style={{ float: 'right', marginTop: '2.5px' }}
          >
            <i className="fe fe-plus mr-1" aria-hidden="true" />
            {formatMessage({ id: 'action.createEmail' })}
          </button>
        </div>
      </div>
      <Suspense fallback={<Spin />}>
        {emailrenewalData?.length >= 0 ? (
          <Table
            dataSource={emailrenewalData}
            columns={columns}
            locale={locale}
            rowKey="_id"
            pagination={{
              onChange(current) {
                setPage(current)
              },
            }}
          />
        ) : (
          <Table
            columns={columns}
            locale={locale}
            loading={{
              indicator: (
                <div>
                  <Spin />
                </div>
              ),
            }}
          />
        )}
      </Suspense>
      <Drawer
        title={formatMessage({ id: 'text.addEmail' })}
        placement="right"
        destroyOnClose
        visible={email?.displayAddEmailDrawer}
        onClose={hideCreate}
        width={settings?.isMobileView ? '100%' : '44%'}
      >
        <AddEmail loading={email?.creating} />
      </Drawer>
      <Drawer
        title={formatMessage({ id: 'text.editHosting' })}
        placement="right"
        destroyOnClose
        visible={email?.displayUpdateEmailDrawer}
        onClose={hideUpdate}
        width={settings?.isMobileView ? '100%' : '44%'}
      >
        <UpdateEmail loading={email?.updating} />
      </Drawer>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(ActiveStoreInfo))
