import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  loadtimeoff,
  getDaya,
  requestTimeOff,
  addTimeOff,
  approveTimeOff,
  loadsettings,
  updateSettings,
  loademployeeDetails,
  getuserlist,
  onsubmitEnrolment,
  loaddepartment,
  loaddesignation,
  updateEmployee,
  getemployeeTimeoff,
  getemployeeBankDetails,
  updateEmployeeBank,
  loadcalculation,
  updatePayrollConfig,
  checkPayroll,
  employeePayslip,
  generateemployeePayslip,
  generatepdfpayslip,
} from 'api/timeoff'

import { getemployeeInfo } from 'api/employee'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadtimeoff)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        timeoffData: response?.data?.timeoffData,
      },
    })
  }
}

export function* GENERATE_PAYSLIP_PDF({ payload }) {
  const { response } = yield call(generatepdfpayslip, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        payslipPdf: response?.data?.payslipPdf,
      },
    })
  }
}

export function* GETUSERLIST() {
  const { response } = yield call(getuserlist)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        userList: response?.data?.userList,
      },
    })
  }
}

export function* LOAD_SETTINGS() {
  const { response } = yield call(loadsettings)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        timeoffSettings: response?.data?.timeoffSettings[0],
      },
    })
  }
}

export function* LOAD_EMPLOYEE_DETAILS() {
  const { response } = yield call(loademployeeDetails)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        employeeDetails: response?.data?.employeeDetails,
      },
    })
  }
}

export function* UPDATE_TIMEOFF_SETTINGS({ payload }) {
  const { response } = yield call(updateSettings, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        timeoffSettings: response?.data?.timeoffSettings[0],
      },
    })
  }
}

export function* GET_REMAINING_DAYS({ payload }) {
  const { response } = yield call(getDaya, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        daysInfo: response?.data?.daysInfo,
        personalDays: Number(response?.data?.personalDays),
        sickDays: Number(response?.data?.sickDays),
        vacationDays: Number(response?.data?.vacationDays),
      },
    })
  }
}

export function* REQUEST_TIMEOFF({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(requestTimeOff, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        creating: false,
        displayAddRequestDrawer: false,
        timeoffData: response?.data?.timeoffData,
      },
    })
  }
}

export function* ADD_TIMEOFF_LOGS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(addTimeOff, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        updating: false,
        displayUpdateRequestDrawer: false,
        timeoffData: response?.data?.timeoffData,
      },
    })
  }
}

export function* APPROVE_REQUEST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      approving: true,
    },
  })
  const { response } = yield call(approveTimeOff, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        approving: false,
        displayApproveModalDrawer: false,
      },
    })
    yield call(LOAD_ALL)
  }
}

export function* ONSUBMIT_ENROLMENT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      enrolling: true,
    },
  })
  const { response } = yield call(onsubmitEnrolment, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        enrolling: false,
        displayEmployeeEnrolmentModal: false,
      },
    })
    yield call(LOAD_EMPLOYEE_DETAILS)
  }
}

// individual employee

export function* LOAD_SINGLE_EMPLOYEE({ payload }) {
  const { response } = yield call(getemployeeInfo, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        profileInfo: response?.data?.profileInfo[0],
      },
    })
  }
}

export function* LOAD_SINGLE_TIMEOFF({ payload }) {
  const { response } = yield call(getemployeeTimeoff, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        employeeTimeOffData: response?.data?.employeeTimeOffData,
        employeeTimeOffPreset: response?.data?.employeeTimeOffPreset,
        personalDays: Number(response?.data?.personalDays),
        sickDays: Number(response?.data?.sickDays),
        vacationDays: Number(response?.data?.vacationDays),
      },
    })
  }
}

export function* LOAD_SINGLE_BANK_DETAIL({ payload }) {
  const { response } = yield call(getemployeeBankDetails, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        bankDetailInfo: response?.data?.bankDetailInfo[0],
      },
    })
  }
}

export function* GETDEPARTMENT() {
  const { response } = yield call(loaddepartment)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        departmentInfo: response?.data?.departmentInfo,
      },
    })
  }
}

export function* GETDESIGNATION() {
  const { response } = yield call(loaddesignation)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        designationInfo: response?.data?.designationInfo,
      },
    })
  }
}

// update employee

export function* UPDATE_EMPLOYEE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(updateEmployee, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        updating: false,
        profileInfo: response?.data?.profileInfo[0],
      },
    })
  }
}
// update employee

// update employee

export function* UPDATE_BANK_DETAILS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(updateEmployeeBank, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        updating: false,
        bankDetailInfo: response?.data?.bankDetailInfo[0],
      },
    })
  }
}
// update employee

export function* CHECK_CALCULATION({ payload }) {
  const { response } = yield call(loadcalculation, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        calculationInfo: response?.data?.calculationInfo,
      },
    })
  }
}

export function* UPDATE_PAYROLL_CONFIG({ payload }) {
  const { response } = yield call(updatePayrollConfig, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isShowPayroll: response?.data?.isShowPayroll,
      },
    })
  }
}

export function* CHECK_PAYROLL({ payload }) {
  const { response } = yield call(checkPayroll, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isShowPayroll: response?.data?.isShowPayroll,
        payrollInformation: response?.data?.payrollInformation,
      },
    })
  }
}

export function* LOAD_SINGLE_PAYSLIP({ payload }) {
  const { response } = yield call(employeePayslip, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        employeePayslipInfo: response?.data?.employeePayslipInfo,
      },
    })
  }
}

export function* GENERATE_PAYSLIP({ payload }) {
  const { response } = yield call(generateemployeePayslip, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        employeePayslipInfo: response?.data?.employeePayslipInfo,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.REQUEST_TIMEOFF, REQUEST_TIMEOFF),
    takeLatest(actions.ADD_TIMEOFF_LOGS, ADD_TIMEOFF_LOGS),
    takeLatest(actions.UPDATE_TIMEOFF_SETTINGS, UPDATE_TIMEOFF_SETTINGS),
    takeLatest(actions.LOAD_SETTINGS, LOAD_SETTINGS),
    takeLatest(actions.APPROVE_REQUEST, APPROVE_REQUEST),
    takeLatest(actions.GET_REMAINING_DAYS, GET_REMAINING_DAYS),
    takeLatest(actions.LOAD_EMPLOYEE_DETAILS, LOAD_EMPLOYEE_DETAILS),
    takeLatest(actions.GETUSERLIST, GETUSERLIST),
    takeLatest(actions.ONSUBMIT_ENROLMENT, ONSUBMIT_ENROLMENT),
    takeLatest(actions.LOAD_SINGLE_EMPLOYEE, LOAD_SINGLE_EMPLOYEE),
    takeLatest(actions.GETDESIGNATION, GETDESIGNATION),
    takeLatest(actions.GETDEPARTMENT, GETDEPARTMENT),
    takeLatest(actions.UPDATE_EMPLOYEE, UPDATE_EMPLOYEE),
    takeLatest(actions.LOAD_SINGLE_TIMEOFF, LOAD_SINGLE_TIMEOFF),
    takeLatest(actions.LOAD_SINGLE_BANK_DETAIL, LOAD_SINGLE_BANK_DETAIL),
    takeLatest(actions.UPDATE_BANK_DETAILS, UPDATE_BANK_DETAILS),
    takeLatest(actions.CHECK_CALCULATION, CHECK_CALCULATION),
    takeLatest(actions.UPDATE_PAYROLL_CONFIG, UPDATE_PAYROLL_CONFIG),
    takeLatest(actions.CHECK_PAYROLL, CHECK_PAYROLL),
    takeLatest(actions.LOAD_SINGLE_PAYSLIP, LOAD_SINGLE_PAYSLIP),
    takeLatest(actions.GENERATE_PAYSLIP, GENERATE_PAYSLIP),
    takeLatest(actions.GENERATE_PAYSLIP_PDF, GENERATE_PAYSLIP_PDF),
  ])
}
