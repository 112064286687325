/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  enquiryData: null,
  searchenquiry: null,
  loading: false,
  creating: false,
  updating: false,
  removing: false,
  sending: false,
  displaySendEmailModal: false,
  displayRemoveEnquiryModal: false,
}

export default function enquiryReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_SEND_EMAIL_MODAL:
      return { ...state, selectedData: action.payload.enquiry, displaySendEmailModal: true }
    case actions.HIDE_SEND_EMAIL_MODAL:
      return { ...state, selectedData: null, displaySendEmailModal: false }
    case actions.SHOW_DELETE:
      return { ...state, selectedData: action.payload.enquiry, displayRemoveEnquiryModal: true }
    case actions.HIDE_DELETE:
      return { ...state, selectedData: null, displayRemoveEnquiryModal: false }

    default:
      return state
  }
}
