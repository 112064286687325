import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Tabs } from 'antd'
import { Link } from 'react-router-dom'
import TimeOffAction from 'redux/timeoff/actions'
import EmployeeDetail from './EmployeeDetail'
import TimeOffDetail from './TimeOffDetail'
import BankDetails from './BankDetails'
import PayrollConfig from './PayrollConfig'
import PayrollGeneration from './PayrollGeneration'

const { TabPane } = Tabs

const mapStateToProps = ({ dispatch, timeOff }) => ({
  dispatch,
  timeOff,
})

const StoreInfo = ({ dispatch, timeOff, intl: { formatMessage } }) => {
  const path = window.location.pathname
  const userId = path.replace('/manage/employee/', '')

  const employeeProfileDatas = timeOff?.profileInfo

  const employeeBankDatas = timeOff?.bankDetailInfo

  useEffect(() => {
    dispatch({
      type: TimeOffAction.LOAD_SINGLE_EMPLOYEE,
      payload: {
        employeeId: userId,
      },
    })
  }, [dispatch, userId])

  useEffect(() => {
    dispatch({
      type: TimeOffAction.LOAD_SINGLE_BANK_DETAIL,
      payload: {
        employeeId: userId,
      },
    })
  }, [dispatch, userId])

  useEffect(() => {
    dispatch({
      type: TimeOffAction.GETDEPARTMENT,
    })
  }, [dispatch])

  useEffect(() => {
    dispatch({
      type: TimeOffAction.GETDESIGNATION,
    })
  }, [dispatch])

  const query = new URLSearchParams(window.location.search)
  const tabname = query.get('tab')

  let tabUrlVal

  if (tabname === 'employee-info') {
    tabUrlVal = '1'
  } else if (tabname === 'timeoff') {
    tabUrlVal = '2'
  } else if (tabname === 'bank-details') {
    tabUrlVal = '3'
  } else if (tabname === 'payroll-config') {
    tabUrlVal = '4'
  } else if (tabname === 'payroll-generation') {
    tabUrlVal = '5'
  }

  return (
    <div>
      <Helmet
        title={formatMessage({
          id: 'title.helmet.employeeInfo',
        })}
      />
      <div className="row">
        <div className="col-md-6">
          <h5 className="mb-4">
            <strong>{employeeProfileDatas?.name}</strong>{' '}
            {`-> ${employeeProfileDatas?.designation} `}
          </h5>
        </div>
      </div>
      <Tabs defaultActiveKey={tabUrlVal}>
        <TabPane
          tab={
            <Link to="?tab=employee-info">
              <span>{formatMessage({ id: 'text.storeino.tab.EmployeeProfile' })}</span>
            </Link>
          }
          key={1}
        >
          <EmployeeDetail
            apiRes={employeeProfileDatas}
            deptRes={timeOff?.departmentInfo}
            desigRes={timeOff?.designationInfo}
          />
        </TabPane>

        <TabPane
          tab={
            <Link to="?tab=timeoff">
              <span>{formatMessage({ id: 'text.storeino.tab.timeOff' })}</span>
            </Link>
          }
          key={2}
        >
          <TimeOffDetail apiRes={employeeProfileDatas} />
        </TabPane>
        <TabPane
          tab={
            <Link to="?tab=bank-details">
              <span>{formatMessage({ id: 'text.storeino.tab.bankDetails' })}</span>
            </Link>
          }
          key={3}
        >
          <BankDetails apiRes={employeeBankDatas} />
        </TabPane>
        <TabPane
          tab={
            <Link to="?tab=payroll-config">
              <span>{formatMessage({ id: 'text.storeino.tab.payrollConfig' })}</span>
            </Link>
          }
          key={4}
        >
          <PayrollConfig />
        </TabPane>
        <TabPane
          tab={
            <Link to="?tab=payroll-generation">
              <span>{formatMessage({ id: 'text.storeino.tab.payrollGeneration' })}</span>
            </Link>
          }
          key={5}
        >
          <PayrollGeneration />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(StoreInfo))
