import { createSelector } from 'reselect'

export const selectReceiptList = state => state?.receipt

export const selectedReceiptList = createSelector([selectReceiptList], receipt => {
  const { searchreceipt, receiptListInfo } = receipt
  if (searchreceipt && receiptListInfo) {
    return receiptListInfo.filter(r =>
      r?.business_name?.toLowerCase().includes(searchreceipt.toLowerCase()),
    )
  }
  return receiptListInfo
})
