import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Input, Form, Button, Select, DatePicker } from 'antd'
import { injectIntl } from 'react-intl'
import AccountsAction from 'redux/accounts/actions'
import moment from 'moment'
import RenewalsAction from 'redux/renewals/actions'

const { Option } = Select

const mapStateToProps = ({ dispatch, accounts, renewals }) => ({
  dispatch,
  accounts,
  renewals,
})

const AddDomainName = ({ accounts, renewals, intl: { formatMessage } }) => {
  const dispatch = useDispatch()

  const onFinish = values => {
    dispatch({
      type: RenewalsAction.SAVE_DOMAIN_INFO,
      payload: {
        accounts_id: values?.accounts_id,
        domainname: values?.domainname,
        registered_date: values?.started_at.format('YYYY-MM-DD'),
        expiry_date: values?.expiry_date.format('YYYY-MM-DD'),
      },
    })
  }

  const [form] = Form.useForm()

  useEffect(() => {
    dispatch({
      type: AccountsAction.LOAD_ALL,
    })
  }, [dispatch])

  function disabledDate(current) {
    return current > moment().startOf('day')
  }

  function disabledDatepast(current) {
    return current < moment().startOf('day')
  }

  const dateFormat = 'YYYY-MM-DD'
  const [valueone] = useState(new Date())
  const datevalue = moment(valueone).format('YYYY-MM-DD')

  const path = window.location.pathname
  const userId = path.replace('/manage/accounts/', '')

  const filteredAccounts = accounts?.accountlistinfo?.filter(data => data.id === userId)

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <Form form={form} className="mb-4" layout="vertical" onFinish={onFinish} hideRequiredMark>
            <div className="row">
              <div className="col-12 mt-3">
                <Form.Item
                  name="accounts_id"
                  label={formatMessage({ id: 'form.label.BusinessName' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Select
                    placeholder={formatMessage({ id: 'form.placeholder.BusinessName' })}
                    size="large"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {filteredAccounts?.map(o => {
                      return (
                        <Option key={o.id} value={o.id}>
                          {o.business_name}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="domainname"
                  label={formatMessage({ id: 'form.label.domainname' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.domainname' })}
                    maxLength={32}
                    style={{ textTransform: 'lowercase' }}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="started_at"
                  label={formatMessage({ id: 'form.label.registeredat' })}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    size="large"
                    // format="MMM D, YYYY"
                    allowClear={false}
                    disabledDate={disabledDate}
                    selected={datevalue}
                    format={dateFormat}
                  />
                </Form.Item>
              </div>

              <div className="col-md-6">
                <Form.Item
                  name="expiry_date"
                  label={formatMessage({ id: 'form.label.expiry_date' })}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    size="large"
                    allowClear={false}
                    disabledDate={disabledDatepast}
                    selected={datevalue}
                    format={dateFormat}
                  />
                </Form.Item>
              </div>
            </div>

            <div className="row mt-1">
              <div className="col-md-12">
                <Form.Item className="text-right">
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    loading={renewals?.creating}
                  >
                    <strong>{formatMessage({ id: 'action.create' })}</strong>
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(AddDomainName))
