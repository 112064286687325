const actions = {
  SET_STATE: 'prospects/SET_STATE',
  LOAD_ALL: 'prospects/LOAD_ALL',
  LOAD_RENEWALS: 'prospects/LOAD_RENEWALS',
  SHOW_CREATE: 'prospects/SHOW_CREATE',
  HIDE_CREATE: 'prospects/HIDE_CREATE',
  SHOW_CREATE_RENEWALS: 'prospects/SHOW_CREATE_RENEWALS',
  HIDE_CREATE_RENEWALS: 'prospects/HIDE_CREATE_RENEWALS',
  SAVE_RENEWALS: 'prospects/SAVE_RENEWALS',
  SHOW_MOVE_RENEWALS: 'prospects/SHOW_MOVE_RENEWALS',
  HIDE_MOVE_RENEWALS: 'prospects/HIDE_MOVE_RENEWALS',
  CHANGE_RENEWAL_MONTH: 'prospects/CHANGE_RENEWAL_MONTH',
  SHOW_MOVE_PROSPECTS: 'prospects/SHOW_MOVE_PROSPECTS',
  HIDE_MOVE_PROSPECTS: 'prospects/HIDE_MOVE_PROSPECTS',
  CHANGE_PROSPECT_MONTH: 'prospects/CHANGE_PROSPECT_MONTH',
  SHOW_TYPE: 'prospects/SHOW_TYPE',
  HIDE_TYPE: 'prospects/HIDE_TYPE',
  CHANGE_TYPE: 'prospects/CHANGE_TYPE',

  SHOW_UPDATE: 'prospects/SHOW_UPDATE',
  HIDE_UPDATE: 'prospects/HIDE_UPDATE',
  SAVE_SALES_PROSPECTS: 'prospects/SAVE_SALES_PROSPECTS',
  UPDATE_SALES_PROSPECTS: 'prospects/UPDATE_SALES_PROSPECTS',
  ADD_ADVANCE: 'prospects/ADD_ADVANCE',
  SHOW_ADVANCE: 'prospects/SHOW_ADVANCE',
  HIDE_ADVANCE: 'prospects/HIDE_ADVANCE',
  GET_BALANCE: 'prospects/GET_BALANCE',
  SHOW_BALANCE: 'prospects/SHOW_BALANCE',
  HIDE_BALANCE: 'prospects/HIDE_BALANCE',
}

export default actions
