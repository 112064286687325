import React, { useState, Suspense, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Table, Spin, Badge, Card, Col, Row, Statistic } from 'antd'
import { getFormattedDate, getFormattedDateWithTime } from 'utils/parser'
import TimeOffAction from 'redux/timeoff/actions'
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'

const mapStateToProps = ({ dispatch, timeOff, user, settings }) => ({
  dispatch,
  timeOff,
  user,
  settings,
})

const ViewDailyReports = ({ timeOff, intl: { formatMessage } }) => {
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()

  const path = window.location.pathname
  const userId = path.replace('/manage/employee/', '')

  useEffect(() => {
    dispatch({
      type: TimeOffAction.LOAD_SINGLE_TIMEOFF,
      payload: {
        employeeId: userId,
      },
    })
  }, [dispatch, userId])

  const timeOffRecords = timeOff?.employeeTimeOffData

  const timeOffPreset = timeOff?.employeeTimeOffPreset

  const apiDataInfo = timeOffRecords

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.timeoffdata' }),
  }

  const columns = [
    {
      title: formatMessage({ id: 'text.sno' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.name' }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: formatMessage({ id: 'text.type' }),
      dataIndex: 'type',
      key: 'type',
      render: text => (
        <>
          {text === '1' && <>Personal Days</>}
          {text === '2' && <>Personal Days</>}
          {text === '3' && <>Personal Days</>}
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.month_year' }),
      dataIndex: 'month_year',
      key: 'month_year',
    },
    {
      title: formatMessage({ id: 'text.noofdays' }),
      dataIndex: 'noofdays',
      key: 'noofdays',
    },
    {
      title: formatMessage({ id: 'text.start_date' }),
      render: record => (
        <>
          {getFormattedDate(record?.start_date)} - {getFormattedDate(record?.end_date)}
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.status' }),
      render: record => (
        <>
          {record?.is_approved === '1' && (
            <>
              <Badge color="green" text={formatMessage({ id: 'text.approved' })} />
            </>
          )}
          {record?.is_approved === '2' && (
            <>
              <Badge color="red" text={formatMessage({ id: 'text.Rejected' })} />
            </>
          )}
          {record?.is_approved === '3' && (
            <>
              <Badge color="blue" text={formatMessage({ id: 'text.Waiting' })} />
            </>
          )}
          {record?.is_approved === '0' && (
            <>
              <Badge color="pink" text={formatMessage({ id: 'text.notApproved' })} />
            </>
          )}
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.requested_date' }),
      dataIndex: 'requested_date',
      key: 'requested_date',
      render: text => getFormattedDateWithTime(text),
    },
  ]

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="mb-4">
            <div className="row">
              <div className="col-md-12">
                <Row gutter={16}>
                  <Col span={8}>
                    <Card bordered={false} style={{ backgroundColor: '#F2F2F2' }}>
                      <Statistic
                        title="Total Personal Days"
                        value={Number(timeOffPreset?.personal)}
                        precision={0}
                        valueStyle={{
                          color: '#000000',
                        }}
                        prefix={<ArrowUpOutlined />}
                        suffix="Days"
                      />
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card bordered={false} style={{ backgroundColor: '#F2F2F2' }}>
                      <Statistic
                        title="Total Sick Days"
                        value={Number(timeOffPreset?.sick)}
                        precision={0}
                        valueStyle={{
                          color: '#000000',
                        }}
                        prefix={<ArrowUpOutlined />}
                        suffix="Days"
                      />
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card bordered={false} style={{ backgroundColor: '#F2F2F2' }}>
                      <Statistic
                        title="Total Vacation Days"
                        value={Number(timeOffPreset?.vacation)}
                        precision={0}
                        valueStyle={{
                          color: '#000000',
                        }}
                        prefix={<ArrowUpOutlined />}
                        suffix="Days"
                      />
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12">
                <Row gutter={16}>
                  <Col span={8}>
                    <Card bordered={false} style={{ backgroundColor: '#FFCD1F' }}>
                      <Statistic
                        title="Personal Days Used"
                        value={Number(timeOff?.personalDays)}
                        precision={0}
                        valueStyle={{
                          color: '#000000',
                        }}
                        prefix={<ArrowDownOutlined />}
                        suffix="Days"
                      />
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card bordered={false} style={{ backgroundColor: '#FFCD1F' }}>
                      <Statistic
                        title="Sick Days Used"
                        value={Number(timeOff?.sickDays)}
                        precision={0}
                        valueStyle={{
                          color: '#000000',
                        }}
                        prefix={<ArrowDownOutlined />}
                        suffix="Days"
                      />
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card bordered={false} style={{ backgroundColor: '#FFCD1F' }}>
                      <Statistic
                        title="Vacation Days Used"
                        value={Number(timeOff?.vacationDays)}
                        precision={0}
                        valueStyle={{
                          color: '#000000',
                        }}
                        prefix={<ArrowDownOutlined />}
                        suffix="Days"
                      />
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="kit__utils__table mt-5">
              <Suspense fallback={<Spin />}>
                {apiDataInfo?.length >= 0 ? (
                  <Table
                    dataSource={apiDataInfo}
                    columns={columns}
                    locale={locale}
                    rowKey="_id"
                    pagination={{
                      onChange(current) {
                        setPage(current)
                      },
                    }}
                  />
                ) : (
                  <Table
                    columns={columns}
                    locale={locale}
                    loading={{
                      indicator: (
                        <div>
                          <Spin />
                        </div>
                      ),
                    }}
                  />
                )}
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default connect(mapStateToProps)(injectIntl(ViewDailyReports))
