/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  taskdata: null,
  userProfile: null,
  taskcategory: null,
  taskNotesInfo: null,
  taskType: null,
  searchTasks: null,
  loading: false,
  creating: false,
  updating: false,
  removing: false,
  displayAddTaskDrawer: false,
  displayUpdateDrawer: false,
  displayTaskAssignedtoModal: false,
  displayTaskDueDateModal: false,
}

export default function tasksReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_CREATE:
      return { ...state, displayAddTaskDrawer: true }
    case actions.HIDE_CREATE:
      return { ...state, displayAddTaskDrawer: false }

    case actions.SHOW_UPDATE:
      return {
        ...state,
        selectedData: action.payload.taskInfo,
        displayUpdateDrawer: true,
      }
    case actions.HIDE_UPDATE:
      return { ...state, selectedData: null, displayUpdateDrawer: false }

    case actions.SHOW_ASSIGNEDTO:
      return { ...state, selectedData: action.payload.taskInfo, displayTaskAssignedtoModal: true }
    case actions.HIDE_ASSIGNEDTO:
      return { ...state, selectedData: null, displayTaskAssignedtoModal: false }

    case actions.SHOW_DUE_DATE_UPDATE:
      return { ...state, selectedData: action.payload.taskInfo, displayTaskDueDateModal: true }
    case actions.HIDE_DUE_DATE_UPDATE:
      return { ...state, selectedData: null, displayTaskDueDateModal: false }

    default:
      return state
  }
}
