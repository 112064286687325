import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Form, Input, Button } from 'antd'
import TimeOffAction from 'redux/timeoff/actions'

const mapStateToProps = ({ dispatch, timeOff }) => ({
  dispatch,
  timeOff,
})

const StoreSettings = ({
  apiRes,
  timeOff,
  intl: { formatMessage },
  dispatch,
}) => {

  const path = window.location.pathname

  const userId = path.replace('/manage/employee/', '')

   const [form] = Form.useForm()

  useEffect(() => {
    if (apiRes) {
      form.setFieldsValue({
        account_holder_name: apiRes?.account_holder_name,
        bank_name: apiRes?.bank_name,
        account_no: apiRes?.account_no,
        ifsc_code: apiRes?.ifsc_code,
        branch_name: apiRes?.branch_name,

      })
    } // eslint-disable-next-line
  }, [form, apiRes])


  const onFinish = values => {
    dispatch({
      type: TimeOffAction.UPDATE_BANK_DETAILS,
      payload: {
        employeeId: userId,
        updatedData: values,
      },
    })
  }
  return (
    <div>
      <div className="card">
        <div className="card-body">
          <Form
            form={form}
            className="mb-4"
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              account_holder_name: apiRes?.account_holder_name,
              bank_name: apiRes?.bank_name,
              account_no: apiRes?.account_no,
              ifsc_code: apiRes?.ifsc_code,
              branch_name: apiRes?.branch_name,
            }}
            hideRequiredMark
          >
            <div className="row">
              <div className="col-md-6">
                <Form.Item
                  name="account_holder_name"
                  label={formatMessage({ id: 'form.label.account_holder_name' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.account_holder_name' })}
                    maxLength={64}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="bank_name"
                  label={formatMessage({ id: 'form.label.bank_name' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.bank_name' })}
                    maxLength={64}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="account_no"
                  label={formatMessage({ id: 'form.label.account_no' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.account_no' })}
                    maxLength={64}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="ifsc_code"
                  label={formatMessage({ id: 'form.label.ifsc_code' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.ifsc_code' })}
                    maxLength={64}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="branch_name"
                  label={formatMessage({ id: 'form.label.branch_name' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.branch_name' })}
                    maxLength={64}
                  />
                </Form.Item>
              </div>
              <div className="col-md-12">
                <Form.Item className="text-right">
                  <Button type="primary" htmlType="submit" size="large" loading={timeOff?.updating}>
                    <strong>{formatMessage({ id: 'action.update' })}</strong>
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(StoreSettings))
