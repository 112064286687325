/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  renewalsData: null,
  salesProspectsData: null,
  searchProspects: null,
  searchRenewals: null,
  selectedData: null,
  renewalData: null,
  balanceResInfo: null,
  loading: false,
  creatingsales: false,
  updatingsales: false,
  updating: false,
  removing: false,
  moving: false,
  displayAddSalesProspects: false,
  displayEditSalesProspects: false,
  displayAddAdvance: false,
  displayViewBalance: false,
  displayAddRenewals: false,
  displayRenewalPopup: false,
  displayProspectPopup: false,
  displayProspectTypePopup: false,
}

export default function prospectsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_CREATE:
      return { ...state, displayAddSalesProspects: true }
    case actions.HIDE_CREATE:
      return { ...state, displayAddSalesProspects: false }
    case actions.SHOW_CREATE_RENEWALS:
      return { ...state, displayAddRenewals: true }
    case actions.HIDE_CREATE_RENEWALS:
      return { ...state, displayAddRenewals: false }
    case actions.SHOW_UPDATE:
      return {
        ...state,
        selectedData: action.payload?.prospectData,
        displayEditSalesProspects: true,
      }
    case actions.HIDE_UPDATE:
      return { ...state, selectedData: null, displayEditSalesProspects: false }
    case actions.SHOW_ADVANCE:
      return {
        ...state,
        selectedData: action.payload?.prospectData,
        displayAddAdvance: true,
      }
    case actions.HIDE_ADVANCE:
      return { ...state, selectedData: null, displayAddAdvance: false }
    case actions.SHOW_BALANCE:
      return { ...state, displayViewBalance: true }
    case actions.HIDE_BALANCE:
      return { ...state, displayViewBalance: false }

    case actions.SHOW_MOVE_RENEWALS:
      return {
        ...state,
        selectedData: action.payload?.renewalData,
        displayRenewalPopup: true,
      }
    case actions.HIDE_MOVE_RENEWALS:
      return { ...state, selectedData: null, displayRenewalPopup: false }

    case actions.SHOW_MOVE_PROSPECTS:
      return {
        ...state,
        selectedData: action.payload?.prospectData,
        displayProspectPopup: true,
      }
    case actions.HIDE_MOVE_PROSPECTS:
      return { ...state, selectedData: null, displayProspectPopup: false }

    case actions.SHOW_TYPE:
      return {
        ...state,
        selectedData: action.payload?.prospectData,
        displayProspectTypePopup: true,
      }
    case actions.HIDE_TYPE:
      return { ...state, selectedData: null, displayProspectTypePopup: false }

    default:
      return state
  }
}
