/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  invoicelist: null,
  typeData: null,
  deleteText: null,
  paymentdetail: null,
  invoicedetail: null,
  selectedData: null,
  searchinvoice: null,
  searchproformainvoice: null,
  proformapdfLocationUrl: null,
  invoicepdfLocationUrl: null,
  loading: false,
  creating: false,
  updating: false,
  removing: false,
  confirming: false,
  converting: false,
  openproforma: false,
  displayCreateInvoiceDrawer: false,
  displayRemoveInvoiceModal: false,
  displayFollowupDrawer: false,
  displayConfirmPODrawer: false,
  displayConvertPODrawer: false,
}

export default function invoiceReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.ADD_ITEM:
      return { ...state, selectedData: action.payload, displayCreateInvoiceDrawer: true }
    case actions.HIDE_ITEM:
      return { ...state, selectedData: null, displayCreateInvoiceDrawer: false }
    case actions.SHOW_DELETE:
      return { ...state, selectedData: action.payload.invoice, displayRemoveInvoiceModal: true }
    case actions.HIDE_DELETE:
      return { ...state, displayRemoveInvoiceModal: false }
    case actions.SHOW_FOLLOWUP:
      return { ...state, selectedData: action.payload.invoice, displayFollowupDrawer: true }
    case actions.HIDE_FOLLOWUP:
      return { ...state, selectedData: null, displayFollowupDrawer: false }
    case actions.SHOW_CONFIRM_MODAL:
      return { ...state, selectedData: action.payload.invoice, displayConfirmPODrawer: true }
    case actions.HIDE_CONFIRM_MODAL:
      return { ...state, selectedData: null, displayConfirmPODrawer: false }
    case actions.SHOW_CONVERTPO_MODAL:
      return { ...state, selectedData: action.payload.invoice, displayConvertPODrawer: true }
    case actions.HIDE_CONVERTPO_MODAL:
      return { ...state, selectedData: null, displayConvertPODrawer: false }

    default:
      return state
  }
}
